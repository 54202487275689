import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Tooltip, TooltipRefProps } from 'react-tooltip';
import axiosClient from '../../../config/axios';

function Appointment({ calendarRef }) {

    const navigate = useNavigate();
    const tooltipRef1 = useRef(null);

    const headerToolbar = {
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        right: 'prev,next today'
    };
    const buttonText = {
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día'
    };
    const views = {
        timeGrid: {
            eventLimit: 6
        }
    };


    const fetchEvents = async (fetchInfo, successCallback, failureCallback) => {
        try {
            const response = await axiosClient.get('/orders/appointments', {
                params: {
                    start: fetchInfo.start.toISOString(),
                    end: fetchInfo.end.toISOString()
                },
            });
            //console.log("response calendario", response);
            const events = response.data.data.map(event => {
                let titleParts = [];

                const booleanFields = ['grua', 'auxilio_vial', 'montacarga', 'corresponsalia', 'taxi'];
                booleanFields.forEach(field => {
                    if (event[field]) {
                        titleParts.push(field.toUpperCase());
                        if (field === 'grua') {
                            if (event.grua_vial === true) {
                                titleParts.push("VIAL");
                            } else if (event.grua_vial === false) {
                                titleParts.push("SINIESTRO");
                            }
                        }
                    }
                });

                if (event.Vehicles && event.Vehicles.length > 0) {
                    const vehicle = event.Vehicles[0];
                    if (vehicle.VehicleModel) {
                        const { VehicleModel } = vehicle;
                        if (VehicleModel.VehicleType) {
                            titleParts.push(VehicleModel.VehicleType.vehicle_type_name);
                        }
                        if (VehicleModel.Brand) {
                            titleParts.push(VehicleModel.Brand.brand_name);
                        }
                        if (VehicleModel.model_name) {
                            titleParts.push(VehicleModel.model_name);
                        }
                    }
                }

                const title = titleParts.join(' ');

                return {
                    id: event.id,
                    title: title,
                    start: event.fecha_hora_cita,
                    extendedProps: {
                        ...event
                    }

                };
            });

            successCallback(events);
        } catch (error) {
            console.error(error);
            failureCallback(error);
        }
    };

    const handleEventClick = (info) => {
        info.jsEvent.preventDefault();
        if (info.event.id) {
            navigate(`/orders/edit/${info.event.id}`);
        }
    }

    return (
        <Row>
            <Col lg={12}>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[multiMonthPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, bootstrapPlugin]}
                    initialView="multiMonthYear"
                    themeSystem="bootstrap"
                    headerToolbar={headerToolbar}
                    buttonText={buttonText}
                    events={fetchEvents}
                    views={views}
                    locale={esLocale}
                    eventClick={(info) => {
                        handleEventClick(info);
                    }}
                    eventDidMount={(info) => {
                        /*var tooltip = new Tooltip(info.el, {
                            title: info.event.extendedProps.description,
                            placement: 'top',
                            trigger: 'hover',
                            container: 'body'
                        });*/
                        // setTooltipTarget(info.el);

                    }}
                    eventMouseEnter={(info) => {
                        info.el.classList.add('show-detail-appointment')
                        //console.log(info.event.extendedProps);
                        const extendedProps = info.event.extendedProps;
                        tooltipRef1.current.open({
                            anchorSelect: '.show-detail-appointment',
                            content: <>
                                {extendedProps?.grua && (
                                    <>
                                        GRÚA {extendedProps?.grua_vial !== null ? (extendedProps?.grua_vial ? 'VIAL' : 'SINIESTRO') : ''}
                                    </>
                                )}
                                {extendedProps?.auxilio_vial && (
                                    <>
                                        AUXILIO VIAL
                                    </>
                                )}
                                {extendedProps?.corresponsalia && (
                                    <>
                                        CORRESPONSALÍA
                                    </>
                                )}
                                {extendedProps?.montacarga && (
                                    <>
                                        MONTACARGA
                                    </>
                                )}
                                {extendedProps?.taxi && (
                                    <>
                                        TAXI
                                    </>
                                )}
                                {extendedProps.Vehicles.map((vehicle, index) => (
                                    <>
                                        <br></br>
                                        {vehicle?.VehicleModel?.VehicleType?.vehicle_type_name} {vehicle?.VehicleModel?.Brand?.brand_name} {vehicle?.VehicleModel?.model_name}{vehicle?.Color ? ', Color: ' + vehicle?.Color?.color_name : ''}
                                    </>
                                ))}
                                <br></br>
                                {extendedProps?.ClientType?.client_type} {extendedProps?.Insurance?.insurance_name}
                                <br></br>
                                {extendedProps?.beneficiario_servicio ? 'Beneficiario: ' + extendedProps?.beneficiario_servicio : ''}

                                <br></br>
                                {extendedProps.OrderLocations.map((orderLocation, index) => (
                                    <>
                                        <br></br>
                                        {extendedProps.OrderLocations.length <= 2 ? (
                                            index === 0 ? 'ORIGEN: ' : 'DESTINO: '
                                        ) : `UBICACIÓN ${index}: `}
                                        {orderLocation?.cuadro_busqueda ? ' ' + orderLocation?.cuadro_busqueda : ''}
                                        {orderLocation?.direccion ? '; Dirección: ' + orderLocation?.direccion : ''}
                                        {orderLocation?.referencia ? '; Referencia: ' + orderLocation?.referencia : ''}
                                    </>
                                ))}
                            </>
                        })

                    }}
                    eventMouseLeave={(info) => {
                        info.el.classList.remove('show-detail-appointment');
                        tooltipRef1.current?.close();
                    }}


                />
            </Col>

            <Tooltip
                ref={tooltipRef1}
                style={{ zIndex: 9999 }}
            />

        </Row>
    );
}

export default Appointment;