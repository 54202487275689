import React from 'react';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function OrderFieldsInformacionTipoServicio({ values, errors, handleBlur, setFieldValue, setFieldTouched }) {




    return (
        <>
            <Row className="mb-4">
                <Col md="2">
                    <Row>
                        <Form.Group as={Col} md="12" >
                            <Form.Check
                                name="grua"
                                type="switch"
                                label="Grúa"
                                checked={values.grua}
                                onChange={(e) => {
                                    setFieldValue('grua', e.target.checked);
                                    setFieldTouched('grua', true);
                                    setFieldValue('grua_vial', null);
                                    setFieldTouched('grua_vial', true);
                                }}
                                onBlur={handleBlur}
                                disabled={values.orderStatuses?.ENTREGA?.has_passed_status}

                            />
                        </Form.Group>
                    </Row>
                    {values.grua && (
                        <Row className='mt-1'>
                            <Form.Group as={Col} md="12" >

                                <div className="btn-group">
                                    <button type='button' className={`btn btn-sm ${values?.grua_vial === true ? 'btn-primary' : 'btn-white'}`}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        onClick={() => {
                                            setFieldTouched('grua_vial', true);
                                            setFieldValue('grua_vial', true);
                                        }} >VIAL</button>
                                    <button type='button' className={`btn btn-sm ${values?.grua_vial === false ? 'btn-primary' : 'btn-white'}`}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        onClick={() => {
                                            setFieldTouched('grua_vial', true);
                                            setFieldValue('grua_vial', false);
                                        }}>SINIESTRO</button>
                                </div>

                                {errors.grua_vial ? (
                                    <div className="invalid-feedback" style={{ display: 'block' }}>{errors.grua_vial}</div>
                                ) : null}

                            </Form.Group>

                        </Row>
                    )}

                </Col >

                <Form.Group as={Col} md="2" >
                    <Form.Check
                        name="auxilio_vial"
                        type="switch"
                        label="Auxilio vial"
                        checked={values.auxilio_vial}
                        onChange={(e) => {
                            setFieldValue('auxilio_vial', e.target.checked);
                            setFieldTouched('auxilio_vial', true);
                        }}
                        onBlur={handleBlur}
                        disabled={values.orderStatuses?.ENTREGA?.has_passed_status}
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" >
                    <Form.Check
                        name="montacarga"
                        type="switch"
                        label="Montacargas"
                        checked={values.montacarga}
                        onChange={(e) => {
                            setFieldValue('montacarga', e.target.checked);
                            setFieldTouched('montacarga', true);
                        }}
                        onBlur={handleBlur}
                        disabled={values.orderStatuses?.ENTREGA?.has_passed_status}
                    />
                </Form.Group>

                <Form.Group as={Col} md="2" >
                    <Form.Check
                        name="corresponsalia"
                        type="switch"
                        label="Corresponsalía"
                        checked={values.corresponsalia}
                        onChange={(e) => {
                            setFieldValue('corresponsalia', e.target.checked);
                            setFieldTouched('corresponsalia', true);
                        }}
                        onBlur={handleBlur}
                        disabled={values.orderStatuses?.ENTREGA?.has_passed_status}
                    />
                </Form.Group>

                <Form.Group as={Col} md="2" >
                    <Form.Check
                        name="taxi"
                        type="switch"
                        label="Taxi"
                        checked={values.taxi}
                        onChange={(e) => {
                            setFieldValue('taxi', e.target.checked);
                            setFieldTouched('taxi', true);
                        }}
                        onBlur={handleBlur}
                        disabled={values.orderStatuses?.ENTREGA?.has_passed_status}
                    />
                </Form.Group>

            </Row >


            <Row className="mb-1">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Observaciones sobre el tipo de servicio</Form.Label>

                    <textarea
                        name="tipo_servicio_observaciones"
                        className={`form-control ${errors.tipo_servicio_observaciones ? 'is-invalid' : ''}`}
                        value={values.tipo_servicio_observaciones}
                        onChange={(e) => {
                            setFieldValue('tipo_servicio_observaciones', e.target.value);
                        }}
                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                    ></textarea>

                    <Form.Control.Feedback type="invalid">
                        {errors.tipo_servicio_observaciones}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>
        </>
    );
}

export default OrderFieldsInformacionTipoServicio;