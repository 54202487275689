import React, { useState, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Tooltip } from 'react-tooltip';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

function ResumeStatusPayment({ }) {
    const { values } = useFormikContext();

    function formatNumberWithCommas(number) {
        if (number) {
            const roundedNumber = parseFloat(number).toFixed(2);
            return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return '';
    }

    return (

        <>
            <div className={`note alert-${values?.excedente_asegurado > 0 && values.totalPendingExcedente !== null ? (values.totalPendingExcedente > 0 ? (values.totalPendingToPay > 0 ? 'danger' : 'warning') : (values.totalPendingToPay > 0 ? 'danger' : 'info')) : (values.totalPendingToPay > 0 ? 'danger' : 'success')} mb-2`}>
                <div className="note-icon">
                    <i className={`fa fa-${values?.excedente_asegurado > 0 && values.totalPendingExcedente !== null ? (values.totalPendingExcedente > 0 ? (values.totalPendingToPay > 0 ? 'frown-open' : 'face-meh') : (values.totalPendingToPay > 0 ? 'frown-open' : 'face-smile')) : (values.totalPendingToPay > 0 ? 'frown-open' : 'face-laugh')}`}></i>
                </div>
                <div className="note-content">
                    <h5><b>Resumen de pagos y facturación</b></h5>
                    <p>
                        {values.totalPendingExcedente != null && (
                            <button type="button" data-tooltip-id='tooltip_excedente_asegurado' className="btn btn-default btn-icon btn-circle btn-sm me-1"><i className="fa-solid fa-circle-info"></i></button>
                        )}

                        {values.totalPendingExcedente != null && (
                            <>
                                {values.totalPendingExcedente > 0
                                    ?
                                    (values.totalPendingToPay <= 0
                                        ? <>La orden no presenta adeudos, pero el Excedente asegurado no se completó con el pago de contado, se completó con el pago a crédito. El monto que no fue cubierto por pago de contado, pero sí por crédito es de: ${formatNumberWithCommas(values.totalPendingExcedente)}</>
                                        : <>
                                            Se ha pagado de contado: ${formatNumberWithCommas(values.contadoTotalPayed)}; y queda un restante del "Excedente asegurado" por:  ${formatNumberWithCommas(values.totalPendingExcedente)}.
                                            {values.creditoTotalPayed >= values.totalPendingExcedente ? 'Es probable que se cubra el excedente con los pagos a crédito.' : ''}
                                        </>
                                    )
                                    : <>EXCEDENTE ASEGURADO CUBIERTO</>
                                }
                            </>
                        )}

                        <Tooltip id='tooltip_excedente_asegurado' place="right" style={{ zIndex: 9999 }} >
                            Excedente asegurado (Monto original neto): ${values?.excedente_asegurado} <br></br>
                            Monto a pagar por Aseguradora (Monto original neto): ${values?.monto_aseguradora_paga}
                        </Tooltip>
                    </p>

                    {values.creditoTotalPayed && values.contadoTotalPayed ?
                        <p>

                            Se ha pagado un total de: ${formatNumberWithCommas((values.creditoTotalPayed || 0) + (values.contadoTotalPayed || 0))}
                        </p>
                        : ''
                    }

                    <p>
                        {values.totalPendingToPay > 0 ? <>Existe un adeudo total por: ${formatNumberWithCommas(values.totalPendingToPay)}</> : (
                            values.totalPendingToPay < 0 ? <>La Orden tiene un Saldo a Favor por: ${formatNumberWithCommas(values.totalPendingToPay * -1)}</> : 'La Orden no presenta adeudos.'
                        )}
                    </p>



                </div>
            </div>
        </>



    );
}

export default ResumeStatusPayment;