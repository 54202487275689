import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/esm/Row';
import Select from 'react-select';
import axiosClient from '../../../../../config/axios.js';

function BankAccountFields({ values, handleChange, touched, errors, setFieldValue }) {

    const [banks, setBanks] = useState([]);

    useEffect(() => {

        axiosClient.get('/banks/all').then(response => {
            const formattedOptions = response.data.data.map(bank => ({
                value: bank.id,
                label: bank.bank
            }));
            setBanks(formattedOptions);
        }).catch(error => {
            console.error('Error fetching banks:', error);
        });

    }, []);

    return (
        <>
            <Row>
                <Form.Group as={Col} md="12">
                    <Form.Label>Número de cuenta</Form.Label>
                    <Form.Control
                        type="text"
                        name="account_number"
                        placeholder="Número de cuenta"
                        value={values.account_number}
                        onChange={handleChange}
                        isInvalid={!!errors.account_number}
                        autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.account_number}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Banco</Form.Label>
                    <Select
                        className={errors.bank_id ? 'is-invalid' : ''}
                        classNamePrefix="select"
                        value={banks.find(bank => bank.value === values?.bank_id?.value)}
                        isClearable={true}
                        isSearchable={true}
                        placeholder='Seleccione un banco'
                        name="bank_id"
                        options={banks}
                        onChange={(e) => {
                            setFieldValue("bank_id", e);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.bank_id}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>


                        
        </>
    );
}

export default BankAccountFields;