import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip'

function OrderAmountsShowSuggestedRates({ values, setFieldValue, setFieldTouched, tarifasCalculadas, keyValue, variableName }) {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            {tarifasCalculadas && tarifasCalculadas.length > 0 ? (

                <>
                    <button onClick={() => setIsOpen(!isOpen)} type="button" data-tooltip-id={`tooltip_${keyValue}`} className="btn btn-default btn-icon btn-circle btn-sm"><i className="fa-solid fa-circle-info"></i></button>
                    <Tooltip id={`tooltip_${keyValue}`} place="right" style={{ zIndex: 9999 }} clickable isOpen={isOpen}>
                        {
                            tarifasCalculadas && (
                                tarifasCalculadas.map((tarifa, index) => (
                                    tarifa[variableName] && (
                                        <Row key={`tarifa_tooltip_${keyValue}_${index}`} className='mb-2'>
                                            <small >
                                                <button type="button" className={`btn default btn-xs me-2 btn-default`} onClick={() => { setFieldTouched(`${keyValue}`, true); setFieldValue(`${keyValue}`, tarifa[variableName]); setIsOpen(false) }}> ${tarifa[variableName]}</button> {tarifa?.CraneType?.crane_type_name ? `${tarifa.CraneType.crane_type_name}` : ''} {tarifa.Insurance ? `(${tarifa.Insurance.insurance_name})` : null}, {tarifa.Location.location_name} ({tarifa.Location.distance} km.)
                                            </small>
                                        </Row>
                                    )
                                ))
                            )
                        }
                    </Tooltip>
                </>

            ) :

                values.ubicacion_tarifaria_origen_id && values.ubicacion_tarifaria_destino_id && values.crane_id && values.client_type_id && (values.client_type_id.client_type_code !== 'ASEGURADORA' || (values.client_type_id.client_type_code === 'ASEGURADORA' && values.insurance_id))
                    ? '' : ''
            }
        </>
    );
}

export default OrderAmountsShowSuggestedRates;