import React from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import OrderVehicles from '../orderVehicles/orderVehicles.js'
import CurrencyInput from 'react-currency-input-field';
import Form from 'react-bootstrap/Form';


function OrderFieldsResultAmount({ print, values, handleBlur, setFieldValue, setFieldTouched, errors }) {

    function formatNumberWithCommas(number) {
        if (number) {
            const roundedNumber = parseFloat(number).toFixed(2);
            return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return '';
    }



    return (

        <Row className="invoice mb-1 col-sm-12 ps-0 pe-0 pb-0 ms-0 me-0">

            <div className="invoice-content">

                <div className="invoice-price">

                    {!print && (
                        <div className="invoice-price-left invoice-subtotal">
                        </div>
                    )}

                    <div className="invoice-price-right invoice-subtotal w-50">
                        <small>SUBTOTAL</small> <span className={`fw-bold ${print ? 'fs-16px pt-3' : ''}`}> ${formatNumberWithCommas(values.subtotal)}</span>
                    </div>
                    {!values.venta_publico && (
                        <div className="invoice-price-right invoice-iva w-50">
                            <small>I.V.A.</small> <span className={`fw-bold ${print ? 'fs-16px pt-3' : ''}`}> ${formatNumberWithCommas(values.iva)}</span>
                        </div>
                    )}
                    <div className={`invoice-price-right w-100 ${print ? 'pb-0 pe-1 ps-1' : ''}`} style={{ display: 'block' }}>
                        <small>TOTAL</small>
                        <span className={`fw-bold ${print ? 'fs-16px pt-3' : ''}`}> ${formatNumberWithCommas(values.total)}</span>

                        {values?.client_type_id?.client_type_code !== 'PARTICULAR' && values?.tipo_pago_id?.code === 'CONTADO/CREDITO' && (
                            <Row className={`${print ? '' : 'mt-4'}`}>
                                <Col md='6' sm='5'>
                                    {print
                                        ?
                                        <div className="d-flex justify-content-end">
                                            <span className={`${print ? 'fs-11px fw-bold' : ''}`}>Aseguradora paga (neto)</span>
                                        </div>
                                        :
                                        <Form.Label style={{ fontSize: 'var(--bs-body-font-size)' }} className={`me-1 ${print ? 'fs-11px' : ''}`}>Aseguradora paga (neto)</Form.Label>
                                    }

                                </Col>
                                <Col md='6' sm='7'>
                                    {print ?
                                        <div className="d-flex align-items-center">
                                            <div className="form-floating">
                                                <div className="input-group smaller">
                                                    <div className="input-group-text"><i className="fa-solid fa-dollar-sign"></i></div>
                                                    <input type="text" className=" form-control bg-white smaller my-small-floating-input-without-label form-print" disabled value={formatNumberWithCommas(values.monto_aseguradora_paga)} />
                                                </div>

                                            </div>
                                        </div>
                                        :
                                        <>
                                            <InputGroup
                                                className={`mt-2 input-group-sm ${errors.excedente_asegurado ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                            >
                                                <InputGroup.Text className={`${print ? 'smaller' : ''}`}>$</InputGroup.Text>
                                                <CurrencyInput
                                                    className={`form-control ${errors.monto_aseguradora_paga ? 'is-invalid' : ''}`}
                                                    prefix=""
                                                    allowNegativeValue={false}
                                                    decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                                    decimalScale={2}
                                                    decimalSeparator="."
                                                    groupSeparator=","
                                                    name="monto_aseguradora_paga"
                                                    value={values.monto_aseguradora_paga}
                                                    onValueChange={(value) => {
                                                        setFieldTouched("monto_aseguradora_paga", true);
                                                        setFieldValue("monto_aseguradora_paga", value);
                                                        //onChangeMontoAseguradoraPaga();
                                                    }}
                                                    autoComplete='off'
                                                    disabled={values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE'}

                                                />
                                            </InputGroup>
                                            {errors.monto_aseguradora_paga ? (
                                                <div className="invalid-feedback">{errors.monto_aseguradora_paga}</div>
                                            ) : null}
                                        </>}
                                </Col>
                                <Col md='6' sm='5'>
                                    {print
                                        ?
                                        <div className="d-flex justify-content-end">
                                            <span className={`${print ? 'fs-11px fw-bold' : ''}`}>Excedente asegurado (neto)</span>
                                        </div>
                                        :
                                        <Form.Label style={{ fontSize: 'var(--bs-body-font-size)' }} className={`me-1 ${print ? 'fs-11px' : ''}`}>Excedente asegurado (neto)</Form.Label>
                                    }

                                </Col>
                                <Col md='6' sm='7'>
                                    {print ?
                                        <div className="d-flex align-items-center">
                                            <div className="form-floating">
                                                <div className="input-group smaller">
                                                    <div className="input-group-text"><i className="fa-solid fa-dollar-sign"></i></div>
                                                    <input type="text" className=" form-control bg-white smaller my-small-floating-input-without-label form-print" disabled value={formatNumberWithCommas(values.excedente_asegurado)} />
                                                </div>

                                            </div>
                                        </div>
                                        :
                                        <>
                                            <InputGroup
                                                className={`mt-2 input-group-sm ${errors.excedente_asegurado ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                            >
                                                <InputGroup.Text className={`${print ? 'smaller' : ''}`}>$</InputGroup.Text>
                                                <CurrencyInput
                                                    className={`form-control ${errors.excedente_asegurado ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                                    prefix=""
                                                    allowNegativeValue={false}
                                                    decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                                    decimalScale={2}
                                                    decimalSeparator="."
                                                    groupSeparator=","
                                                    name="excedente_asegurado"
                                                    value={values.excedente_asegurado}
                                                    onValueChange={(value) => {
                                                        setFieldTouched("excedente_asegurado", true);
                                                        setFieldValue("excedente_asegurado", value);
                                                    }}
                                                    autoComplete='off'
                                                    disabled={values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE'}
                                                />
                                            </InputGroup>
                                            {errors.excedente_asegurado ? (
                                                <div className="invalid-feedback">{errors.excedente_asegurado}</div>
                                            ) : null}
                                        </>
                                    }

                                </Col>

                            </Row>
                        )}

                    </div>
                </div>

                {!values.manejar_precios_netos && values.venta_publico && (
                    <div className="mt-2 col-md-12 pb-2">
                        <div className="alert alert-warning alert-dismissible fade show h-100 mb-0">
                            Se está sumando un monto de IVA correspondiente a $ {values.iva}
                            <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                        </div>
                    </div>
                )}




                <Form.Group as={Col} className="mt-3 float-end" >

                    {print ?

                        <>
                            {values.venta_publico && (
                                <div className="form-check mt-2 mb-2 col-sm-12">
                                    <input className="form-check-input" type="checkbox" checked={values.venta_publico ? true : false} onChange={() => { }} />
                                    <label className="form-check-label fs-11px" htmlFor="flexCheckDefault">
                                        VENTA AL PÚBLICO
                                    </label>
                                </div>
                            )}
                        </>

                        :
                        <Form.Check
                            name="venta_publico"
                            type="switch"
                            label="Venta al público"
                            checked={values.venta_publico}
                            onChange={(e) => {
                                setFieldValue('venta_publico', e.target.checked);
                            }}
                            onBlur={handleBlur}
                            disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                        />
                    }



                </Form.Group>
            </div>
        </Row >
    );
}

export default OrderFieldsResultAmount