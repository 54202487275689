import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import CreatableSelect from 'react-select/creatable';


function AssistanceVehicleFields({ values, handleChange, setFieldValue, setFieldTouched, errors, touched }) {
    const navigate = useNavigate();
    const { Formik } = formik;

    const [isLoadingVehicleType, setIsLoadingVehicleType] = useState(false);
    const [isLoadingBrand, setIsLoadingBrand] = useState(false);
    const [isLoadingVehicleModel, setIsLoadingVehicleModel] = useState(false);

    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [brands, setBrands] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [yearsVehicles, setYearsVehicles] = useState([]);

    useEffect(() => {

        axiosClient.get('/brands/all').then(response => {
            const formattedOptions = response.data.data.map(brand => ({
                value: brand.id,
                label: brand.brand_name
            }));
            setBrands(formattedOptions);
        }).catch(error => {
            console.error('Error fetching brands:', error);
        });

        axiosClient.get('/vehicleTypes/all').then(response => {
            const formattedOptions = response.data.data.map(vehicleType => ({
                value: vehicleType.id,
                label: vehicleType.vehicle_type_name
            }));
            setVehicleTypes(formattedOptions);
        }).catch(error => {
            console.error('Error fetching vehicleTypes:', error);
        });
        setYearsVehicles(fetchYearsVehicles());

    }, []);

    useEffect(() => {
        if (touched.vehicle_type_id) {
            setFieldTouched('vehicle_type_id', false);

            setFieldValue('brand_id', '');
            setFieldValue('vehicle_model_id', '');
        }

        if (touched.brand_id) {
            setFieldTouched('brand_id', false);

            setFieldValue('vehicle_model_id', '');
        }
    }, [
        values.vehicle_type_id,
        values.brand_id,
    ]);

    const fetchYearsVehicles = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1900;

        const yearOptions = [];
        for (let year = currentYear + 1; year >= startYear; year--) {
            yearOptions.push({ value: year.toString(), label: year.toString() });
        }
        return yearOptions;
    };

    const fetchVehicleModels = async (vehicle_type, brand) => {
        axiosClient.get(`/vehicleModels/vehicleModelsByVehicleTypeAndBrand`, {
            params: {
                brand_id: brand.value,
                vehicle_type_id: vehicle_type.value
            }
        }).then(response => {
            const formattedOptions = response.data.data.map(vehicleModel => ({
                value: vehicleModel.id,
                label: vehicleModel.model_name
            }));
            setVehicleModels(formattedOptions)
        }).catch(error => {
            console.error('Error fetching vehicleModel:', error);
        });

    };

    useEffect(() => {
        const fetchVehicleData = async () => {
            if (values.brand_id) {
                try {
                    await fetchVehicleModels(values.vehicle_type_id, values.brand_id)
                } catch (error) {
                    console.error('Error fetching vehicleModels:', error);
                }
            } else {
                setFieldValue(`vehicle_model_id`, '');
            }
        };

        fetchVehicleData();
    }, [
        values.brand_id
    ]);


    const handleCreateVehicleType = (inputValue) => {

        Swal.fire({
            title: '¿Está seguro que desea agregar un nuevo Tipo de Vehículo?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar Tipo de vehículo',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoadingVehicleType(true);

                axiosClient.post('/vehicleTypes/save', { vehicle_type_name: inputValue }).then(response => {
                    setIsLoadingVehicleType(false);
                    const newOption = {
                        value: response.data.id,
                        label: response.data.vehicle_type_name
                    };

                    setVehicleTypes((prev) => [...prev, newOption]);
                    setFieldValue('vehicle_type_id', newOption);

                }).catch(err => {
                    Swal.fire({
                        title: 'Ups!',
                        text: 'Ha ocurrido un error con el registro de Tipo de Vehículo. Intenta registrarlo en el catálogo.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: false,
                    });
                });
            }
        })

    };

    const handleCreateBrand = (inputValue) => {
        Swal.fire({
            title: '¿Está seguro que desea agregar una nueva Marca?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar Marca',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {


                setIsLoadingBrand(true);

                axiosClient.post('/brands/save', { brand_name: inputValue }).then(response => {
                    setIsLoadingBrand(false);
                    const newOption = {
                        value: response.data.id,
                        label: response.data.brand_name
                    };

                    setBrands((prev) => [...prev, newOption]);
                    setFieldValue('brand_id', newOption);

                }).catch(err => {
                    Swal.fire({
                        title: 'Ups!',
                        text: 'Ha ocurrido un error con el registro de la Marca. Intenta registrarla en el catálogo.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: false,
                    });
                });
            }
        })
    };

    const handleCreateVehicleModel = (inputValue, setIsLoadingVehicleModel, setVehicleModels) => {
        Swal.fire({
            title: '¿Está seguro que desea agregar un nuevo modelo de vehículo?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar Modelo',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoadingVehicleModel(true);

                axiosClient.post('/vehicleModels/save', {
                    model_name: inputValue,
                    vehicle_type_id: values.vehicle_type_id.value,
                    brand_id: values.brand_id.value

                }).then(response => {
                    setIsLoadingVehicleModel(false);
                    const newOption = {
                        value: response.data.id,
                        label: response.data.model_name
                    };

                    setVehicleModels((prev) => [...prev, newOption]);
                    setFieldValue('vehicle_model_id', newOption);

                }).catch(err => {

                    Swal.fire({
                        title: 'Ups!',
                        text: 'Ha ocurrido un error con el registro del Modelo de Vehículo. Intenta registrarlo en el catálogo.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: false,
                    });
                });
            }
        })
    };

    return (
        <>
            <Row className="mb-1">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Tipo de vehículo</Form.Label>
                    <CreatableSelect
                        isClearable
                        isDisabled={false}
                        isLoading={isLoadingVehicleType}
                        onChange={(newValue) => { setFieldTouched('vehicle_type_id', true); setFieldValue(`vehicle_type_id`, newValue); }}
                        onCreateOption={(inputValue) => handleCreateVehicleType(inputValue)}
                        options={vehicleTypes}
                        value={values.vehicle_type_id}
                        name={`vehicle_type_id`}
                        placeholder='Selecciona o registra un tipo de vehículo'
                        className={errors.vehicle_type_id ? 'is-invalid' : ''}
                        formatCreateLabel={(inputValue) => `Crear nuevo Tipo de Vehículo: ${inputValue}`}
                        noOptionsMessage={() => `No se encontraron opciones`}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.vehicle_type_id}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-1">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Marca</Form.Label>
                    <CreatableSelect
                        isClearable
                        isDisabled={false}
                        isLoading={isLoadingBrand}
                        onChange={(newValue) => { setFieldTouched('brand_id', true); setFieldValue(`brand_id`, newValue) }}
                        onCreateOption={(inputValue) => handleCreateBrand(inputValue)}
                        options={brands}
                        value={values.brand_id}
                        name={`vehiculos.brand_id`}
                        placeholder='Selecciona o registra una marca'
                        className={errors.brand_id ? 'is-invalid' : ''}
                        formatCreateLabel={(inputValue) => `Crear nueva Marca: ${inputValue}`}
                        noOptionsMessage={() => `No se encontraron opciones`}


                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.brand_id}
                    </Form.Control.Feedback>
                </Form.Group>

            </Row>
            <Row className="mb-1">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Modelo</Form.Label>
                    <CreatableSelect
                        isClearable
                        isDisabled={false}
                        isLoading={isLoadingVehicleModel}
                        onChange={(newValue) => setFieldValue(`vehicle_model_id`, newValue)}
                        onCreateOption={(inputValue) => handleCreateVehicleModel(inputValue, setIsLoadingVehicleModel, setVehicleModels)}
                        options={vehicleModels}
                        value={values.vehicle_model_id}
                        name={`vehiculos.vehicle_model_id`}
                        placeholder='Selecciona o registra un modelo'
                        className={errors.vehicle_model_id ? 'is-invalid' : ''}
                        formatCreateLabel={(inputValue) => `Crear nuevo Modelo: ${inputValue}`}
                        noOptionsMessage={() => `No se encontraron opciones`}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.vehicle_model_id}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-1">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Año</Form.Label>
                    <Select
                        name={`anio`}
                        value={values.anio}
                        className={errors.anio ? 'is-invalid' : ''}
                        placeholder="Seleccione un año"
                        options={yearsVehicles}
                        isInvalid={!!errors.anio}
                        onChange={(e) => {
                            setFieldValue(`anio`, e);
                        }}
                        isDisabled={false}
                        isClearable={true}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.anio ? errors.anio : null}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Número de serie</Form.Label>
                    <Form.Control
                        type="text"
                        name="numero_serie"
                        placeholder="Número de Serie"
                        value={values.numero_serie}
                        onChange={handleChange}
                        isInvalid={!!errors.numero_serie}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.numero_serie}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Placas</Form.Label>
                    <Form.Control
                        type="text"
                        name="placas"
                        placeholder="Placas"
                        value={values.placas}
                        onChange={handleChange}
                        isInvalid={!!errors.placas}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.placas}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>


        </>

    );
}

export default AssistanceVehicleFields;