import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import axiosClient from '../../../../config/axios.js';
import { FieldArray, useFormikContext } from 'formik';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Button } from 'bootstrap/dist/js/bootstrap.bundle.js';
import DateTime from 'react-datetime';
import Select from 'react-select';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import CurrencyInput from 'react-currency-input-field';
import { Tooltip } from 'react-tooltip';


const TablePayments = forwardRef(({ paymentTypeCode, valuesKey, disabled }, ref) => {
    const { id } = useParams();
    const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axiosClient.get(`/payments/activePaymentsByOrder/${id}`, {
                params: {
                    only_active_payments: true,
                    payment_type_code: paymentTypeCode
                },
            });

            const formatted = response.data.rows.map((payment) => {

                console.log("item payment response", payment);
                return {
                    id: payment.id,
                    payment_date: moment(payment.payment_date).toDate(),
                    payment_method_id: payment.PaymentMethod.id,
                    payment_method_code: payment.PaymentMethod.payment_method_code,
                    bank_account_id: payment?.BankAccount ? { value: payment?.BankAccount.id, label: payment?.BankAccount.Bank.bank + ' (' + payment?.BankAccount.account_number + ')' } : '',
                    creator: (payment.creator_payment.name + ' ' + payment.creator_payment.first_surname + ' ' + (payment.creator_payment.second_surname ? payment.creator_payment.second_surname : '')).trim(),
                    payment_amount: payment.payment_amount,
                    created_at: payment.createdAt,
                    payment_type_id: payment.PaymentType?.id
                };
            });
            console.log("payments formatted", formatted);
            setFieldTouched(valuesKey, true);
            setFieldValue(valuesKey, formatted);
        } catch (error) {
            console.error(error);
        }
    };

    useImperativeHandle(ref, () => ({
        fetchData,
    }));


    useEffect(() => {

        const fetchPaymentMethods = axiosClient.get('/paymentMethods/all').then(response => {
            const formattedOptions = response.data.data.map(paymentMethod => ({
                value: paymentMethod.id,
                label: paymentMethod.payment_method,
                payment_method_code: paymentMethod.payment_method_code,
                payment_method_id: paymentMethod.id,
                id: paymentMethod.id
            }));
            setPaymentMethods(formattedOptions);
        }).catch(error => {
            console.error('Error fetching paymentMethods:', error);
        });

        const fetchBankAccounts = axiosClient.get('/bankAccounts/all').then(response => {
            const formattedOptions = response.data.data.map(bankAccount => ({
                value: bankAccount.id,
                label: bankAccount.Bank.bank + ' (' + bankAccount.account_number + ')',
            }));
            setBankAccounts(formattedOptions);
        }).catch(error => {
            console.error('Error fetching bankAccounts:', error);
        });

        Promise.all([fetchPaymentMethods, fetchBankAccounts]).then(() => {
            fetchData();
        });

    }, [id]);

    return (
        <>

            <FieldArray
                name={`${valuesKey}`}
                render={arrayHelpers => {
                    const payments = values[valuesKey];
                    return (
                        <>
                            <Row className='mb-3'>
                                <div className='col-md-12'>
                                    <div className="">
                                        <table className="table table">
                                            <thead>
                                                <tr>
                                                    <th width="5%"></th>
                                                    <th width="15%">PAGO REGISTRADO POR</th>
                                                    <th width="20%">FECHA DE PAGO</th>
                                                    <th width="20%">MÉTODO DE PAGO</th>
                                                    <th width="20%"></th>
                                                    <th width="20%">MONTO PAGADO</th>
                                                </tr>
                                            </thead>


                                            <tbody key="montos_grua_foraneo">


                                                {values[valuesKey] && values[valuesKey].length > 0
                                                    ? values[valuesKey].map((payment, index) => (

                                                        <tr key={`tr_payments_${index}`} className={`table  table-sm bg-white-100 table-bordered ${index % 2 === 1 ? '' : ''}`}>
                                                            <td className='text-center align-middle'>
                                                                {!disabled && (

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-icon btn-circle btn-sm"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(index); // Elimina la fila del FieldArray
                                                                        }}
                                                                    >
                                                                        <i className="fa-solid fa-minus"></i>
                                                                    </button>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {payment.created_at && (
                                                                    <>
                                                                        {format(
                                                                            new Date(payment.created_at),
                                                                            'dd/MMM/yyyy',
                                                                            { locale: es }
                                                                        )} {' '}
                                                                        {format(
                                                                            new Date(payment.created_at),
                                                                            'hh:mm a',
                                                                            { locale: es }
                                                                        )}
                                                                        <br></br>
                                                                        {payment.creator}
                                                                    </>
                                                                )}

                                                            </td>
                                                            <td>
                                                                <Col md='12'>
                                                                    <Row>
                                                                        <Form.Group as={Col} md="12" >
                                                                            <DateTime
                                                                                name={`${valuesKey}.${index}.payment_date`}
                                                                                inputProps={{
                                                                                    placeholder: 'Fecha pago',
                                                                                    disabled: disabled,
                                                                                    readOnly: true
                                                                                }}
                                                                                closeOnSelect={true}
                                                                                locale="es"
                                                                                value={values[valuesKey][index]?.payment_date ? format(new Date(values[valuesKey][index].payment_date), 'EEEE dd/MMMM/yyyy', { locale: es }) : ''}
                                                                                className={`${errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey] && errors[valuesKey][index].payment_date ? 'is-invalid' : ''}`}
                                                                                dateFormat="dddd DD/MMMM/YYYY"
                                                                                timeFormat={false}
                                                                                initialViewMode="days"
                                                                                onOpen={(e) => {
                                                                                    setFieldTouched(`${valuesKey}.${index}.payment_date`, true);
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    setFieldValue(`${valuesKey}.${index}.payment_date`, e ? e : '');
                                                                                }}
                                                                                isInvalid={touched.payment_date && !!errors.payment_date}

                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey] && errors[valuesKey][index].payment_date ? errors[valuesKey][index].payment_date : ''}
                                                                            </Form.Control.Feedback>
                                                                            {errors.payment_date ? (
                                                                                <div className="invalid-feedback">{errors.payment_date}</div>
                                                                            ) : null}

                                                                        </Form.Group>
                                                                    </Row>
                                                                </Col>
                                                            </td>

                                                            <td>
                                                                <Col md='12'>
                                                                    <Row>
                                                                        <Form.Group as={Col} md="12" >

                                                                            <Select
                                                                                name={`${valuesKey}.${index}.payment_method`}
                                                                                options={paymentMethods}
                                                                                className={`${errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey][index].payment_method_code ? 'is-invalid' : ''}`}
                                                                                placeholder="Selecciona el método de pago"
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                value={paymentMethods.find(item => item.payment_method_code === values[valuesKey][index].payment_method_code)}
                                                                                onChange={(e) => {
                                                                                    //console.log("payment method seleccionado", e);
                                                                                    setFieldTouched(`${valuesKey}.${index}.payment_method_code`, true);
                                                                                    setFieldValue(`${valuesKey}.${index}.payment_method_code`, e ? e.payment_method_code : null);
                                                                                    setFieldValue(`${valuesKey}.${index}.payment_method_id`, e ? e.payment_method_id : null);
                                                                                    setFieldTouched(`${valuesKey}.${index}.bank_account_id`, true);
                                                                                    setFieldValue(`${valuesKey}.${index}.bank_account_id`, '');
                                                                                }}
                                                                                isDisabled={disabled}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey][index].payment_method_code ? errors[valuesKey][index]?.payment_method_code : ''}
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Row>
                                                                </Col>
                                                            </td>
                                                            <td>
                                                                <Col md='12'>
                                                                    {(index < (values[valuesKey]?.length || 0) && payments[index]?.payment_method_code === 'TRANSFER') && (
                                                                        <Row>

                                                                            <Form.Group as={Col} md='12' className="">
                                                                                <Select
                                                                                    name={`${valuesKey}.${index}.bank_account_id`}
                                                                                    options={bankAccounts}
                                                                                    className={`${errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey][index].bank_account_id ? 'is-invalid' : ''}`}
                                                                                    placeholder="Selecciona la cuenta bancaria"
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    value={bankAccounts.find(item => item?.value === values[valuesKey][index]?.bank_account_id?.value)}
                                                                                    onChange={(e) => {
                                                                                        setFieldTouched(`${valuesKey}.${index}.bank_account_id`, true);
                                                                                        setFieldValue(`${valuesKey}.${index}.bank_account_id`, e ? e : '');
                                                                                    }}
                                                                                    isDisabled={disabled}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey][index].bank_account_id ? errors[valuesKey][index]?.bank_account_id : ''}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>


                                                                        </Row>
                                                                    )}
                                                                </Col>
                                                            </td>
                                                            <td>

                                                                <Form.Group as={Col} md='12' className="">

                                                                    <InputGroup className={` ${errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey][index]?.payment_amount ? 'is-invalid' : ''}`}>
                                                                        <InputGroup.Text><i className="fa-solid fa-sack-dollar"></i></InputGroup.Text>
                                                                        <CurrencyInput
                                                                            autoComplete='off'
                                                                            className={`form-control ${errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey][index]?.payment_amount ? 'is-invalid' : ''}`}
                                                                            prefix=""
                                                                            allowNegativeValue={false}
                                                                            decimalsLimit={2}
                                                                            decimalScale={2}
                                                                            decimalSeparator="."
                                                                            groupSeparator=","
                                                                            name={`${valuesKey}.${index}.payment_amount`}
                                                                            value={values[valuesKey][index].payment_amount}
                                                                            onValueChange={(value) => {
                                                                                setFieldTouched(`${valuesKey}.${index}.payment_amount`, true);
                                                                                setFieldValue(`${valuesKey}.${index}.payment_amount`, value);
                                                                            }}
                                                                            disabled={disabled}
                                                                        />
                                                                    </InputGroup>
                                                                    {errors[valuesKey] && errors[valuesKey][index] && errors[valuesKey][index].payment_amount ? (
                                                                        <div className="invalid-feedback"  style={{ color: '#ea4335' }}>{errors[valuesKey][index].payment_amount}</div>
                                                                    ) : null}

                                                                </Form.Group>


                                                            </td>

                                                        </tr>



                                                    ))
                                                    : null
                                                }
                                                {!disabled && (
                                                    <tr>
                                                        <td colSpan={7}>

                                                            <Row className='mb-3'>
                                                                <div className="col-md-12">
                                                                    <div className="d-flex align-items-center float-end">
                                                                        <div >
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-success btn-icon btn-circle btn-sm "

                                                                                onClick={() =>
                                                                                    arrayHelpers.push({
                                                                                        id: "",
                                                                                        payment_date: "",
                                                                                        payment_method_id: 0,
                                                                                        created_by_user_id: '',
                                                                                        payment_amount: '',
                                                                                        bank_account_id: '',
                                                                                        payment_type_code: paymentTypeCode
                                                                                    })
                                                                                }
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </button>

                                                                        </div>

                                                                        <div className='ms-1'>
                                                                            Agregar pago
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Row>

                                                        </td>
                                                    </tr>
                                                )}


                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </Row >

                        </>
                    );
                }}
            />

        </>
    );
});

export default TablePayments;