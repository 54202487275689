import React, { useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import VideoThumbnail from 'react-video-thumbnail';


function OrderFieldsEvidenciasEvidencia({ evidence, gallery, values, galleryNumber, setFieldValue, handleDeleteIdImage, setLightboxOpen, setGalleryNumber, setImageIndex, globalImageIndexStart }) {
    //const [values.evidences[galleryNumber]?.images, setSelectedFiles] = useState([]);

    const handleImageClick = (index) => {
        console.log("globalImageIndexStart", globalImageIndexStart);
        const globalIndex = globalImageIndexStart + index;
        setGalleryNumber(galleryNumber);
        setImageIndex(globalIndex);
        setLightboxOpen(true);
        console.log("setImageIndex", globalIndex);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            acceptedFiles.forEach((file, index) => {
                setFieldValue(`evidences.${galleryNumber}.images`, [...values.evidences[galleryNumber].images, { id: null, file }]);
            });
        },
        accept: {
            'image/*': [],
            'video/*': []
        },
        multiple: true
    });



    const handleDeleteImage = (index) => {
        let updatedFiles = [...values.evidences[galleryNumber]?.images];

        if (updatedFiles[index]?.id) {
            handleDeleteIdImage(updatedFiles[index]?.id);
        }
        updatedFiles.splice(index, 1);

        setFieldValue(`evidences.${galleryNumber}.images`, updatedFiles)
    };


    return (

        <>

            {
                Array.isArray(values.evidences[galleryNumber]?.images) && values.evidences[galleryNumber]?.images.map((fileObject, index) => (

                    <div className={'col-lg-3 col-md-2 ' + (gallery[`group${galleryNumber}`] || gallery.all ? '' : 'd-none ')} key={`divEvidenceFiles_${index}`}>
                        <div className="image w-100">
                            <div className="image-inner">
                                {fileObject?.thumbnail_url ?
                                    <img
                                        key={index}
                                        src={fileObject?.thumbnail_url}
                                        alt={''}
                                        onClick={() => handleImageClick(index)}
                                    />
                                    :

                                    (fileObject?.file?.type?.includes('video') ?
                                        <>
                                            <div className='draft-image'>
                                                <VideoThumbnail
                                                    videoUrl={URL.createObjectURL(fileObject?.file)}
                                                    thumbnailHandler={
                                                        (thumbnail) =>
                                                            //console.log("ss")
                                                            setFieldValue(`evidences.${galleryNumber}.images.${index}.video_thumbnail`, thumbnail)
                                                    }
                                                />
                                            </div>

                                            <span className="badge me-1 bg-purple">Video</span>
                                        </>
                                        : <img
                                            key={index}
                                            src={URL.createObjectURL(fileObject?.file)}
                                            alt={fileObject?.file.name}
                                            onClick={() => handleImageClick(index)}
                                            className='draft-image'
                                        />
                                    )
                                }

                                <span className="badge me-1 bg-secondary">{evidence.evidence}</span>
                                {(fileObject?.file_type === 'VIDEO' || fileObject?.file_type === 'IMAGE') && (
                                    <Row>
                                        <Col>
                                            <span className="badge me-1 bg-purple">
                                                {fileObject?.file_type === 'VIDEO' ? 'Video' : 'Imagen'}
                                            </span>
                                        </Col>

                                    </Row>
                                )}

                                <p className="image-caption">
                                    <button type='button' className='btn btn-danger' onClick={() => handleDeleteImage(index)}>
                                        <span className='fa fa-solid fa-trash'></span>
                                    </button>
                                </p>
                            </div>
                        </div>


                    </div>

                ))
            }
            <div className={'col-lg-3 col-md-2 ' + ((gallery[`group${galleryNumber}`] && !gallery.all) ? '' : 'd-none ')} >
                <div className="image w-100">
                    <div className="image-inner">
                        <div {...getRootProps({ style: dropzoneStyles })}>
                            <input {...getInputProps()} />
                            <p>Arrastra y suelta archivos de "{evidence.evidence}" aquí, o haz clic para seleccionarlos. </p>
                        </div>
                    </div>
                </div>





            </div >


        </>

    );
}

const dropzoneStyles = {
    border: '2px dashed #eeeeee',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

const thumbnailContainerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '20px',
};

const thumbnailStyles = {
    width: '150px',
    height: '150px',
    objectFit: 'cover',
    margin: '5px',
    cursor: 'pointer',
};

export default OrderFieldsEvidenciasEvidencia;