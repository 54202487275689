import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import CurrencyInput from 'react-currency-input-field';

function OrderAmountsFieldArrayAdicionalPrint({ print, index, values, errors, arrayHelpers, montosExtras, setFieldValue, setFieldTouched }) {
    function formatNumberWithCommas(number) {
        if (number) {
            const roundedNumber = parseFloat(number).toFixed(2);
            return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return '';
    }

    return (
        <tbody>
            <tr className='fs-9px'>
                <td className='align-middle text-center'>
                    <label className="form-label">ADICIONAL</label>
                </td>
                <td>
                </td>
                <td>

                    <span className="text-dark">
                        <span className={`float-end fs-9px`}>{montosExtras[index].monto_extra_descripcion}</span>
                    </span>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="form-floating">
                            <div className="input-group smaller">
                                <div className="input-group-text"><i className="fa-solid fa-dollar-sign"></i></div>
                                <input type="text" className=" form-control bg-white smaller my-small-floating-input-without-label form-print" disabled value={formatNumberWithCommas(values.montosExtras[index].monto_extra_monto)} />
                            </div>
                        </div>
                    </div>
                </td>


            </tr>
        </tbody>
    );
}

export default OrderAmountsFieldArrayAdicionalPrint;