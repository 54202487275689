import React from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import OrderStatusAsignadaSinEnviarSeleccOperador from './orderStatusAsignadaSinEnviarSeleccOperador.js';


function OrderStatusAsignadaSinEnviar({ operadores, fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB }) {
    const { id } = useParams();
    const { Formik } = formik;

    return (
        <>
            <Formik
                validationSchema={yup.object().shape({
                    operadores: yup.array().min(1, "Se debe seleccionar al menos un operador"),
                    observaciones: yup.string()
                })}
                initialValues={{
                    operadores: [],
                    observaciones: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code: 'ASIGNADA_SIN_ENVIAR',
                        observations: values.observaciones,
                        operadores: values.operadores
                    }).then(async response => {
                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);


                        if (modalInstance) {

                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                        console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    });
                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>
                        <div className="modal-header">
                            <h4 className="modal-title">Asignar operador</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>


                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <OrderStatusAsignadaSinEnviarSeleccOperador
                                            values={values}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            operadores={operadores}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Observaciones</Form.Label>
                                        <textarea
                                            name="observaciones"
                                            className={`form-control ${errors.observaciones ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setFieldValue('observaciones', e.target.value);
                                            }}
                                            value={values.observaciones}
                                        ></textarea>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.observaciones}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                            </Form>


                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" onClick={handleSubmit} disabled={errors.operadores}>Actualizar estatus</Button>
                        </div>
                    </>
                )}
            </Formik>


        </>
    );
}

export default OrderStatusAsignadaSinEnviar;