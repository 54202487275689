import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axiosClient from '../../../../config/axios.js';
import CreatableSelect from 'react-select/creatable';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import { ErrorMessage } from 'formik';
import TripleToggleSwitch from '../../../../components/Toggle/TripleToggleSwitch.jsx';

function Vehicle({ token, index, arrayHelpers, setFieldValue, setFieldTouched, touched, values, errors, handleCreateVehicleType, handleCreateBrand, handleCreateVehicleModel, colors, vehicleTypes, brands, yearsVehicles, shieldingTypes, isLoadingVehicleType, isLoadingBrand, disabled }) {

    const [isLoadingVehicleModel, setIsLoadingVehicleModel] = useState(false);
    const [vehicleModels, setVehicleModels] = useState([]);

    const handleTripleToggle = (e, fieldValue) => {
        let booleano = null;
        if (e === "true") {
            booleano = true;
        } else if (e === "false") {
            booleano = false;
        }
        //console.log(booleano);
        setFieldValue(fieldValue, booleano);
    };

    const fetchVehicleModels = async (vehicle_type, brand) => {
        axiosClient.get(`/vehicleModels/vehicleModelsByVehicleTypeAndBrand`, {
            params: {
                brand_id: brand.value,
                vehicle_type_id: vehicle_type.value
            }
        }).then(response => {
            const formattedOptions = response.data.data.map(vehicleModel => ({
                value: vehicleModel.id,
                label: vehicleModel.model_name
            }));
            setVehicleModels(formattedOptions)
        }).catch(error => {
            console.error('Error fetching vehicleModel:', error);
        });

    };



    useEffect(() => {
        if (touched.vehiculos && touched.vehiculos[index] && touched.vehiculos[index].vehicle_type_id) {
            setFieldValue(`vehiculos.${index}.brand_id`, '');
            setFieldValue(`vehiculos.${index}.vehicle_model_id`, '');
        }
    }, [
        values.vehiculos[index].vehicle_type_id
    ]);

    useEffect(() => {
        if (values.vehiculos[index].registrar_volumen) {
            if (values.vehiculos[index].volumen_largo && values.vehiculos[index].volumen_ancho && values.vehiculos[index].volumen_altura) {
                setFieldValue(`vehiculos.${index}.volumen_m3`, values.vehiculos[index].volumen_largo * values.vehiculos[index].volumen_ancho * values.vehiculos[index].volumen_altura);
            } else {
                setFieldValue(`vehiculos.${index}.volumen_m3`, '');
            }
        } else {
            setFieldValue(`vehiculos.${index}.volumen_largo`, '');
            setFieldValue(`vehiculos.${index}.volumen_ancho`, '');
            setFieldValue(`vehiculos.${index}.volumen_altura`, '');
            setFieldValue(`vehiculos.${index}.volumen_m3`, '');
        }

    }, [
        values.vehiculos[index].registrar_volumen,
        values.vehiculos[index].volumen_largo,
        values.vehiculos[index].volumen_ancho,
        values.vehiculos[index].volumen_altura,
    ]);



    useEffect(() => {
        const fetchVehicleData = async () => {
            if (values.vehiculos[index].brand_id) {
                try {
                    await fetchVehicleModels(values.vehiculos[index].vehicle_type_id, values.vehiculos[index].brand_id)
                } catch (error) {
                    console.error('Error fetching vehicleModels:', error);
                }
            } else {
                setFieldValue(`vehiculos.${index}.vehicle_model_id`, '');
            }
        };

        fetchVehicleData();
    }, [
        values.vehiculos[index].brand_id
    ]);


    
    return (
        <Row className="mb-3" key={index}>

            <table className="table table-bordered widget-table rounded" style={{ width: '100%' }}>
                <thead>
                    <tr className="text-nowrap">
                        <th className="d-flex align-items-center ">
                            {!disabled && (
                                <div >
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-icon btn-circle btn-sm"
                                        onClick={() => {
                                            arrayHelpers.remove(index); // Elimina la fila del FieldArray
                                        }}
                                    >
                                        <i className="fa-solid fa-minus"></i>
                                    </button>

                                </div>
                            )}

                            <div className='ms-1'>
                                Vehículo {index + 1}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width: '50%' }}>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Tipo de vehículo</Form.Label>
                                    <CreatableSelect
                                        isClearable
                                        isDisabled={isLoadingVehicleType || values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        isLoading={isLoadingVehicleType}
                                        onChange={(newValue) => setFieldValue(`vehiculos.${index}.vehicle_type_id`, newValue)}
                                        onCreateOption={(inputValue) => handleCreateVehicleType(inputValue, index)}
                                        options={vehicleTypes}
                                        value={values.vehiculos[index].vehicle_type_id}
                                        name={`vehiculos.${index}.vehicle_type_id`}
                                        placeholder='Selecciona o registra un tipo de vehículo'
                                        className={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].vehicle_type_id ? 'is-invalid' : ''}
                                        formatCreateLabel={(inputValue) => `Crear nuevo Tipo de Vehículo: ${inputValue}`}
                                        noOptionsMessage={() => `No se encontraron opciones`}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].vehicle_type_id ? errors.vehiculos[index].vehicle_type_id : null}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Marca</Form.Label>
                                    <CreatableSelect
                                        isClearable
                                        isDisabled={isLoadingBrand || !values.vehiculos[index].vehicle_type_id || values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        isLoading={isLoadingBrand}
                                        onChange={(newValue) => setFieldValue(`vehiculos.${index}.brand_id`, newValue)}
                                        onCreateOption={(inputValue) => handleCreateBrand(inputValue, index)}
                                        options={brands}
                                        value={values.vehiculos[index].brand_id}
                                        name={`vehiculos.${index}.brand_id`}
                                        placeholder='Selecciona o registra una marca'
                                        className={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].brand_id ? 'is-invalid' : ''}
                                        formatCreateLabel={(inputValue) => `Crear nueva Marca: ${inputValue}`}
                                        noOptionsMessage={() => `No se encontraron opciones`}


                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].brand_id ? errors.vehiculos[index].brand_id : null}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Modelo</Form.Label>
                                    <CreatableSelect
                                        isClearable
                                        isDisabled={isLoadingVehicleModel || !values.vehiculos[index].vehicle_type_id || !values.vehiculos[index].brand_id || values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        isLoading={isLoadingVehicleModel}
                                        onChange={(newValue) => setFieldValue(`vehiculos.${index}.vehicle_model_id`, newValue)}
                                        onCreateOption={(inputValue) => handleCreateVehicleModel(inputValue, setIsLoadingVehicleModel, setVehicleModels, index)}
                                        options={vehicleModels}
                                        value={values.vehiculos[index].vehicle_model_id}
                                        name={`vehiculos.${index}.vehicle_model_id`}
                                        placeholder='Selecciona o registra un modelo'
                                        className={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].vehicle_model_id ? 'is-invalid' : ''}
                                        formatCreateLabel={(inputValue) => `Crear nuevo Modelo: ${inputValue}`}
                                        noOptionsMessage={() => `No se encontraron opciones`}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].vehicle_model_id ? errors.vehiculos[index].vehicle_model_id : null}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Año</Form.Label>
                                    <Select
                                        name={`vehiculos.${index}.anio`}
                                        value={values.vehiculos[index].anio}
                                        className={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].anio ? 'is-invalid' : ''}
                                        placeholder="Seleccione un año"
                                        options={yearsVehicles}
                                        isInvalid={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].anio && !!errors.vehiculos[index].anio}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.anio`, e);
                                        }}
                                        isDisabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        isClearable={true}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].anio ? errors.vehiculos[index].anio : null}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className='mb-1'>
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Color</Form.Label>
                                    <Select
                                        name={`vehiculos.${index}.color_id`}
                                        options={colors}
                                        className={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].color_id ? 'is-invalid' : ''}
                                        defaultValue=''
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.data.color,
                                                color: state.data.colorContrast,
                                            }),
                                        }}
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                        formatOptionLabel={({ label, color }) => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '20px', height: '20px', backgroundColor: color, marginRight: '8px' }} />
                                                {label}
                                            </div>
                                        )}
                                        isClearable={true}
                                        isSearchable={true}
                                        value={values.vehiculos[index].color_id}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.color_id`, e);
                                        }}
                                        isDisabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].color_id ? errors.vehiculos[index].color_id : null}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                        </td>
                        <td style={{ width: '50%' }}>

                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Placas</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`vehiculos.${index}.placas`}
                                        placeholder="Placas"
                                        value={values.vehiculos[index].placas}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.placas`, e.target.value);
                                        }}
                                        isInvalid={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].placas && !!errors.vehiculos[index].placas}
                                        autoComplete="off"
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].placas ? errors.vehiculos[index].placas : null}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >

                                    <Form.Label>Número de serie</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`vehiculos.${index}.numero_serie`}
                                        placeholder="Número de serie"
                                        value={values.vehiculos[index].numero_serie}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.numero_serie`, e.target.value);
                                        }}
                                        isInvalid={errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].numero_serie && !!errors.vehiculos[index].numero_serie}
                                        autoComplete="off"
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].numero_serie ? errors.vehiculos[index].numero_serie : null}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Blindaje del vehículo</Form.Label>
                                    <Select
                                        name={`vehiculos.${index}.shielding_type_id`}
                                        options={shieldingTypes}
                                        className={errors.shielding_type_id ? 'is-invalid' : ''}
                                        placeholder="Selecciona el tipo de blindaje u omítalo si no aplica"
                                        isClearable={true}
                                        isSearchable={true}
                                        value={values.vehiculos[index].shielding_type_id}
                                        onChange={(e) => {
                                            setFieldTouched("someWithShielding", true);
                                            setFieldValue(`vehiculos.${index}.shielding_type_id`, e ? e : '');
                                        }}
                                        isDisabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].shielding_type_id ? errors.vehiculos[index].shielding_type_id : null}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Row>

                            <Row className="mb-1">
                                <Form.Group as={Col} md='12' >
                                    <Form.Label>Carga</Form.Label>

                                    <InputGroup
                                        className={`mb-3 ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].carga_kg ? 'is-invalid' : ''}`}
                                    >
                                        <CurrencyInput
                                            className={`form-control ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].carga_kg ? 'is-invalid' : ''}`}
                                            prefix=""
                                            allowNegativeValue={false}
                                            decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                            decimalScale={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            name={`vehiculos.${index}.carga_kg`}
                                            value={values.vehiculos[index].carga_kg}
                                            onValueChange={(value) => {
                                                setFieldTouched("someWithCarga", true);
                                                setFieldValue(`vehiculos.${index}.carga_kg`, value);
                                            }}
                                            autoComplete='off'
                                            disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                        />
                                        <InputGroup.Text >kg.</InputGroup.Text>
                                    </InputGroup>
                                    {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].carga_kg ? (
                                        <div className="invalid-feedback">{errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].carga_kg}</div>
                                    ) : null}

                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <div className="col-md-12">
                                    <Form.Group as={Col} >
                                        <Form.Check
                                            name={`vehiculos.${index}.registrar_volumen`}
                                            type="switch"
                                            label="Registrar volumen"
                                            checked={values.vehiculos[index].registrar_volumen}
                                            onChange={(e) => {
                                                setFieldTouched("someWithVolumen", true);
                                                setFieldValue(`vehiculos.${index}.registrar_volumen`, e.target.checked);
                                            }}
                                            disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                        />
                                    </Form.Group>
                                </div>
                            </Row>

                            {values.vehiculos[index].registrar_volumen && (
                                <>
                                    <Row className="mb-3">
                                        <div className="col-md-3">

                                            <Form.Group as={Col} md='12' >
                                                <Form.Label>Largo</Form.Label>

                                                <InputGroup
                                                    className={`mb-3 ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_largo ? 'is-invalid' : ''}`}
                                                >
                                                    <CurrencyInput
                                                        className={`form-control ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_largo ? 'is-invalid' : ''}`}
                                                        prefix=""
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                                        decimalScale={2}
                                                        decimalSeparator="."
                                                        groupSeparator=","
                                                        name={`vehiculos.${index}.volumen_largo`}
                                                        value={values.vehiculos[index].volumen_largo}
                                                        onValueChange={(value) => setFieldValue(`vehiculos.${index}.volumen_largo`, value)}
                                                        autoComplete='off'
                                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                                    />
                                                    <InputGroup.Text >m.</InputGroup.Text>
                                                </InputGroup>
                                                {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_largo ? (
                                                    <div className="invalid-feedback">{errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_largo}</div>
                                                ) : null}

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group as={Col} md='12' >
                                                <Form.Label>Ancho</Form.Label>

                                                <InputGroup
                                                    className={`mb-3 ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_ancho ? 'is-invalid' : ''}`}
                                                >
                                                    <CurrencyInput
                                                        className={`form-control ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_ancho ? 'is-invalid' : ''}`}
                                                        prefix=""
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                                        decimalScale={2}
                                                        decimalSeparator="."
                                                        groupSeparator=","
                                                        name={`vehiculos.${index}.volumen_ancho`}
                                                        value={values.vehiculos[index].volumen_ancho}
                                                        onValueChange={(value) => setFieldValue(`vehiculos.${index}.volumen_ancho`, value)}
                                                        autoComplete='off'
                                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                                    />
                                                    <InputGroup.Text >m.</InputGroup.Text>
                                                </InputGroup>
                                                {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_ancho ? (
                                                    <div className="invalid-feedback">{errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_ancho}</div>
                                                ) : null}

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group as={Col} md='12' >
                                                <Form.Label>Altura</Form.Label>

                                                <InputGroup
                                                    className={`mb-3 ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_altura ? 'is-invalid' : ''}`}
                                                >
                                                    <CurrencyInput
                                                        className={`form-control ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_altura ? 'is-invalid' : ''}`}
                                                        prefix=""
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                                        decimalScale={2}
                                                        decimalSeparator="."
                                                        groupSeparator=","
                                                        name={`vehiculos.${index}.volumen_altura`}
                                                        value={values.vehiculos[index].volumen_altura}
                                                        onValueChange={(value) => setFieldValue(`vehiculos.${index}.volumen_altura`, value)}
                                                        autoComplete='off'
                                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                                    />
                                                    <InputGroup.Text >m.</InputGroup.Text>
                                                </InputGroup>
                                                {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_altura ? (
                                                    <div className="invalid-feedback">{errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_altura}</div>
                                                ) : null}

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group as={Col} md='12' >
                                                <Form.Label>Volumen</Form.Label>

                                                <InputGroup
                                                    className={`mb-3 ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_m3 ? 'is-invalid' : ''}`}
                                                >
                                                    <CurrencyInput
                                                        disabled
                                                        className={`form-control ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_m3 ? 'is-invalid' : ''}`}
                                                        prefix=""
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                                        decimalScale={2}
                                                        decimalSeparator="."
                                                        groupSeparator=","
                                                        name={`vehiculos.${index}.volumen_m3`}
                                                        value={values.vehiculos[index].volumen_m3}
                                                        onValueChange={(value) => setFieldValue(`vehiculos.${index}.volumen_m3`, value)}
                                                        autoComplete='off'
                                                    />
                                                    <InputGroup.Text >m<sup>3</sup>.</InputGroup.Text>
                                                </InputGroup>
                                                {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_m3 ? (
                                                    <div className="invalid-feedback">{errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].volumen_m3}</div>
                                                ) : null}

                                            </Form.Group>
                                        </div>
                                    </Row>
                                </>
                            )}







                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Form.Label>Condición del vehículo</Form.Label>
                            <Row className="mb-3">

                                <Form.Group as={Col} md="6" >
                                    <Form.Check
                                        name={`vehiculos.${index}.estado_llantas_giran`}
                                        type="switch"
                                        label="Llantas giran"
                                        checked={values.vehiculos[index].estado_llantas_giran}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.estado_llantas_giran`, e.target.checked);
                                        }}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" >
                                    <Form.Check
                                        name={`vehiculos.${index}.estado_volante_gira`}
                                        type="switch"
                                        label="Volante gira"
                                        checked={values.vehiculos[index].estado_volante_gira}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.estado_volante_gira`, e.target.checked);
                                        }}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" >
                                    <Form.Check
                                        name={`vehiculos.${index}.estado_en_cajon`}
                                        type="switch"
                                        label="Vehículo en cajón"
                                        checked={values.vehiculos[index].estado_en_cajon}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.estado_en_cajon`, e.target.checked);
                                        }}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    />
                                </Form.Group>



                                <Form.Group as={Col} md="6" className='mt-2'>
                                    <TripleToggleSwitch
                                        keyIndex={`switch_estado_pie_calle_${index}`}
                                        value={values.vehiculos[index].estado_pie_calle}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        states={[
                                            {
                                                id: "0",
                                                value: null,
                                                check_title: "N/A",
                                                position: "left",

                                            },
                                            {
                                                id: "1",
                                                value: true,
                                                check_title: "Pie de calle",
                                                position: "center",

                                            },
                                            {
                                                id: "2",
                                                value: false,
                                                check_title: "Garage",
                                                position: "right",
                                            },
                                        ]}
                                        onChange={(e) => {
                                            //console.log("index", index);
                                            handleTripleToggle(e, `vehiculos.${index}.estado_pie_calle`);
                                        }}
                                    />
                                </Form.Group>


                                <Form.Group as={Col} md="6" className='mt-2'>
                                    <TripleToggleSwitch
                                        keyIndex={`switch_estado_neutral_${index}`}
                                        value={values.vehiculos[index].estado_neutral}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        states={[
                                            {
                                                id: "0",
                                                value: null,
                                                check_title: "N/A",
                                                position: "left",

                                            },
                                            {
                                                id: "1",
                                                value: true,
                                                check_title: "Neutral",
                                                position: "center",

                                            },
                                            {
                                                id: "2",
                                                value: false,
                                                check_title: "Parking",
                                                position: "right",
                                            },
                                        ]}
                                        onChange={(e) => {
                                            //console.log(`vehiculos.${index}.estado_neutral`);
                                            handleTripleToggle(e, `vehiculos.${index}.estado_neutral`);

                                        }}
                                    />
                                </Form.Group>



                            </Row>

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>

                            {values.auxilio_vial && (
                                <>
                                    <Form.Label>Tipo de Auxilio Vial</Form.Label>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" >
                                            <Form.Check
                                                name={`vehiculos.${index}.asistencia_paso_corriente`}
                                                type="switch"
                                                label="Paso de Corriente"
                                                checked={values.vehiculos[index].asistencia_paso_corriente}
                                                onChange={(e) => {
                                                    setFieldValue(`vehiculos.${index}.asistencia_paso_corriente`, e.target.checked);
                                                }}
                                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" >
                                            <Form.Check
                                                name={`vehiculos.${index}.asistencia_combustible`}
                                                type="switch"
                                                label="Carga de Combustible"
                                                checked={values.vehiculos[index].asistencia_combustible}
                                                onChange={(e) => {
                                                    setFieldValue(`vehiculos.${index}.asistencia_combustible`, e.target.checked);
                                                }}
                                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" >
                                            <Form.Check
                                                name={`vehiculos.${index}.asistencia_cambio_neumatico`}
                                                type="switch"
                                                label="Cambio de neumático"
                                                checked={values.vehiculos[index].asistencia_cambio_neumatico}
                                                onChange={(e) => {
                                                    setFieldValue(`vehiculos.${index}.asistencia_cambio_neumatico`, e.target.checked);
                                                }}
                                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                            />
                                        </Form.Group>


                                        <Form.Group as={Col} md="6" >
                                            <Form.Check
                                                name={`vehiculos.${index}.asistencia_cerrajeria`}
                                                type="switch"
                                                label="Cerrajería"
                                                checked={values.vehiculos[index].asistencia_cerrajeria}
                                                onChange={(e) => {
                                                    setFieldValue(`vehiculos.${index}.asistencia_cerrajeria`, e.target.checked);
                                                }}
                                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                            />
                                        </Form.Group>
                                    </Row>
                                </>
                            )}


                            <Row className="mb-1">


                                <Form.Group as={Col} md="12" >




                                    <Form.Label>Observaciones del vehículo</Form.Label>

                                    <textarea
                                        name={`vehiculos.${index}.observaciones`}
                                        className={`form-control ${errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].observaciones ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setFieldValue(`vehiculos.${index}.observaciones`, e.target.value);
                                        }}
                                        value={values.vehiculos[index].observaciones}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    ></textarea>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehiculos && errors.vehiculos[index] && errors.vehiculos[index].observaciones ? errors.vehiculos[index].observaciones : null}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Row>
                        </td>
                    </tr>
                </tbody>
            </table>

        </Row>
    );
}

export default Vehicle;