import React from 'react';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Field, ErrorMessage } from 'formik';
import Col from 'react-bootstrap/Col';
import CurrencyInput from 'react-currency-input-field';

function OrderAmountsFieldArrayGenericoPrint({ print, values, setFieldValue, setFieldTouched, handleChange, errors, tipoServicio, subTotalKeyName, subTotalLabel, banderazoKey, casetasKey, tarifasCalculadas, tarifasCasetas, primeraVariableLabel, segundaVariableLabel, segundaVariableSufix, terceraVariableLabel, cuartaVariableLabel, primeraVariableKey, segundaVariableKey, terceraVariableKey, cuartaVariableKey, disabled }) {
    function formatNumberWithCommas(number) {
        if (number) {
            const roundedNumber = parseFloat(number).toFixed(2);
            return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return '';
    }

    return (
        <>

            <tr className={`${print ? 'fs-9px ' : ''}`} >
                <td rowSpan={cuartaVariableLabel ? 3 : 2} className='align-middle text-center'>
                    <Form.Label>{tipoServicio}</Form.Label>
                </td>
                {primeraVariableLabel && primeraVariableKey && (
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="form-floating">
                                <div className="input-group smaller">
                                    <div className="input-group-text"><i className="fa-solid fa-dollar-sign"></i></div>
                                    <input type="text" className=" form-control bg-white smaller my-small-floating-input form-print" disabled value={formatNumberWithCommas(values[primeraVariableKey])} />
                                </div>
                                <label htmlFor="floatingInput" className="d-flex align-items-center floating-grouped-icon-left form-print">{primeraVariableLabel}</label>
                            </div>
                        </div>

                    </td>

                )}

                {segundaVariableLabel && segundaVariableKey && (
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="form-floating">
                                <div className="input-group smaller">

                                    <input type="text" className=" form-control bg-white smaller my-small-floating-input form-print" disabled value={formatNumberWithCommas(values[segundaVariableKey])} />
                                    <div className="input-group-text"><small>{segundaVariableSufix}</small></div>
                                </div>
                                <label htmlFor="floatingInput" className="d-flex align-items-center floating-grouped-icon-right form-print">{segundaVariableLabel}</label>
                            </div>
                        </div>
                    </td>
                )}

                {subTotalKeyName && (
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="form-floating">
                                <div className="input-group smaller">
                                    <div className="input-group-text"><i className="fa-solid fa-dollar-sign"></i></div>
                                    <input type="text" className=" form-control bg-white smaller my-small-floating-input form-print" disabled value={formatNumberWithCommas(values[subTotalKeyName])} />
                                </div>
                                <label htmlFor="floatingInput" className="d-flex align-items-center floating-grouped-icon-left form-print">{subTotalLabel}</label>
                            </div>
                        </div>
                    </td>
                )}


            </tr>
            {terceraVariableKey && (

                <tr className={`${print ? 'fs-11px' : ''}`} >
                    <td>

                    </td>
                    <td >
                        <div className="d-flex justify-content-end">
                            <span className={`${print ? 'fs-9px' : ''}`}>{terceraVariableLabel}</span>
                        </div>

                    </td>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="form-floating">
                                    <div className="input-group smaller">
                                        <div className="input-group-text"><i className="fa-solid fa-dollar-sign"></i></div>
                                        <input type="text" className=" form-control bg-white smaller my-small-floating-input-without-label form-print" disabled value={formatNumberWithCommas(values[terceraVariableKey])} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {terceraVariableKey === 'corresponsalia_subtotal' && (
                            <span className={`${print ? 'fs-9px' : ''}`}>{values?.corresponsalia_observaciones}</span>
                        )}

                    </td>

                </tr>
            )}

            {cuartaVariableLabel && cuartaVariableKey && (
                <tr className={`${print ? 'fs-11px' : ''}`} >


                    <td>

                    </td>
                    <td>
                        <div className="d-flex justify-content-end">
                            <span className={`${print ? 'fs-9px' : ''}`}>{cuartaVariableLabel}</span>

                        </div>
                    </td>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="form-floating">
                                <div className="input-group smaller">
                                    <div className="input-group-text"><i className="fa-solid fa-dollar-sign"></i></div>
                                    <input type="text" className=" form-control bg-white smaller my-small-floating-input-without-label form-print" disabled value={formatNumberWithCommas(values[cuartaVariableKey])} />
                                </div>

                            </div>
                        </div>

                    </td>
                </tr>

            )}

        </>
    );
}

export default OrderAmountsFieldArrayGenericoPrint;