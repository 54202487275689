import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './ComponentToPrint.css';

function OrderVehiclesPrint({ orderData }) {

    return (
        <>


            {orderData?.vehiculos && orderData?.vehiculos.length > 0
                ? orderData?.vehiculos.map((vehiculo, index) => (
                    <div key={`div_vehiculos_${index}`}>


                        {orderData?.vehiculos.length > 1 && index !== 0 && (
                            <hr className="bg-gray-700 mt-0 mb-1" />
                        )}


                        <form className="row row-cols-sm-auto align-items-center mb-0 mt-1" action="/" method="POST">
                            <Col sm='2'>
                                <div className="form-floating ">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.vehicle_type_id?.label ? vehiculo?.vehicle_type_id?.label : ''} />
                                    <label htmlFor="floatingInput" className={`d-flex align-items-center my-small-label-floating-input form-print ${orderData?.vehiculos.length > 1 ? ' my-small-label-floating-input-important' : ''}`}>{orderData?.vehiculos.length > 1 ? `VEHÍCULO ${index + 1}` : 'TIPO'}</label>
                                </div>
                            </Col>

                            <Col sm='1'>
                                <div className="form-floating">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.brand_id?.label ? vehiculo?.brand_id?.label : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">MARCA</label>
                                </div>
                            </Col>

                            <Col sm='2'>
                                <div className="form-floating">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.vehicle_model_id?.label ? vehiculo?.vehicle_model_id?.label : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">MODELO</label>
                                </div>
                            </Col>

                            <Col sm='1'>
                                <div className="form-floating">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.anio?.label ? vehiculo?.anio?.label : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">AÑO</label>
                                </div>
                            </Col>

                            <Col sm='1'>
                                <div className="form-floating">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.color_id?.label ? vehiculo?.color_id?.label : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">COLOR</label>
                                </div>
                            </Col>

                            <Col sm='2'>
                                <div className="form-floating">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.placas ? vehiculo?.placas : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">PLACAS</label>
                                </div>
                            </Col>

                            <Col sm='3'>
                                <div className="form-floating">
                                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.numero_serie ? vehiculo?.numero_serie : ''} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">SERIE</label>
                                </div>
                            </Col>



                        </form>
                        {(vehiculo?.shielding_type_id || vehiculo?.carga_kg || vehiculo?.registrar_volumen) && (
                            <form className="row row-cols-sm-auto align-items-center mb-0 mt-0" action="/" method="POST">

                                {vehiculo?.shielding_type_id && (
                                    <Col sm='3'>
                                        <div className="form-floating">
                                            <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.shielding_type_id?.label ? vehiculo?.shielding_type_id?.label : ''} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">BLINDAJE</label>
                                        </div>
                                    </Col>
                                )}

                                {vehiculo?.carga_kg && (
                                    <Col sm='3'>
                                        <div className="form-floating">
                                            <div className="input-group smaller">
                                                <input type="text" className=" form-control bg-white smaller my-small-floating-input form-print" disabled value={vehiculo?.carga_kg ? vehiculo?.carga_kg : ''} />
                                                <div className="input-group-text">kg.</div>
                                            </div>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-9px floating-grouped-icon-right form-print">CARGA</label>
                                        </div>
                                    </Col>
                                )}

                                {vehiculo?.registrar_volumen && (
                                    <Col sm='6'>
                                        <div className="form-floating">
                                            <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={vehiculo?.volumen_largo ? `${vehiculo?.volumen_largo} m. x ${vehiculo?.volumen_ancho} m. x ${vehiculo?.volumen_altura} m. (${vehiculo?.volumen_m3} m3.)` : ''} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">VOLUMEN (largo x ancho x altura)</label>
                                        </div>
                                    </Col>

                                )}

                            </form>
                        )}

                        <form className="row row-cols-sm-auto g-3 align-items-center mt-0 mb-0" action="/" method="POST">
                            <div className="form-check mt-0 mb-0 col-sm-2">
                                <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={vehiculo?.estado_llantas_giran ? true : false} />
                                <label className="form-check-label " htmlFor="flexCheckDefault">
                                    LLANTAS GIRAN
                                </label>
                            </div>

                            <div className="form-check mt-0 mb-0 col-sm-2">
                                <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={vehiculo?.estado_volante_gira ? true : false} />
                                <label className="form-check-label " htmlFor="flexCheckDefault">
                                    VOLANTE GIRA
                                </label>
                            </div>

                            <div className="form-check mt-0 mb-0 col-sm-2">
                                <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={vehiculo?.estado_en_cajon ? true : false} />
                                <label className="form-check-label " htmlFor="flexCheckDefault">
                                    EN CAJÓN
                                </label>
                            </div>

                            {vehiculo?.estado_pie_calle !== null && (
                                <div className="form-check mt-0 mb-0 col-sm-2">
                                    <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={true} />
                                    <label className="form-check-label " htmlFor="flexCheckDefault">
                                        {vehiculo?.estado_pie_calle ? 'PIE DE CALLE' : 'GARAGE'}
                                    </label>
                                </div>
                            )}

                            {vehiculo?.estado_neutral !== null && (
                                <div className="form-check mt-0 mb-0 col-sm-2">
                                    <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={true} />
                                    <label className="form-check-label " htmlFor="flexCheckDefault">
                                        {vehiculo?.estado_neutral ? 'NEUTRAL' : 'PARKING'}
                                    </label>
                                </div>
                            )}
                        </form>

                        {orderData.auxilio_vial && (

                            <form className="row row-cols-sm-auto g-3 align-items-center mt-0 mb-0" action="/" method="POST">
                                <div className="mt-0 mb-0 col-sm-1">
                                    <label className="form-label col-form-label fs-9px">
                                        ASISTENCIA:
                                    </label>
                                </div>
                                <div className="form-check mt-0 mb-0 col-sm-2">
                                    <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={vehiculo?.asistencia_paso_corriente ? true : false} />
                                    <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                        PASO DE CORRIENTE
                                    </label>
                                </div>

                                <div className="form-check mt-0 mb-0 col-sm-3">
                                    <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={vehiculo?.asistencia_combustible ? true : false} />
                                    <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                        CARGA COMBUSTIBLE
                                    </label>
                                </div>

                                <div className="form-check mt-0 mb-0 col-sm-3">
                                    <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={vehiculo?.asistencia_cambio_neumatico ? true : false} />
                                    <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                        CAMBIO NEUMÁTICO
                                    </label>
                                </div>

                                <div className="form-check mt-0 mb-0 col-sm-2">
                                    <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={vehiculo?.asistencia_cerrajeria ? true : false} />
                                    <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                                        CERRAJERÍA
                                    </label>
                                </div>
                            </form>

                        )}
                        {vehiculo?.observaciones && (
                            <Row className='mb-0 pb-0 mt-0'>
                                <div className="col-sm-12 simple-text">
                                    <label className="form-label col-form-label">OBSERVACIONES: </label>
                                    <span className=''>{' ' + vehiculo?.observaciones}</span>
                                </div>

                            </Row>

                        )}




                    </div>

                ))
                : null
            }

            {orderData?.bienes_observaciones && (
                <>
                    <hr className="bg-gray-500 mt-0 mb-0" />
                    <Row className="mb-0 mt-1 pb-0">
                        <Form.Group as={Col} md="12" className='simple-text'>
                            <Form.Label >OBSERVACIONES GENERALES SOBRE LOS BIENES:</Form.Label>
                            <span>{' ' + orderData?.bienes_observaciones}</span>
                        </Form.Group>
                    </Row>
                </>
            )}



        </>
    );
}

export default OrderVehiclesPrint;