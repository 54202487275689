import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import InsurersFields from './insurersFields';
import axiosClient from '../../../../config/axios.js';


function EditInsurance() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        insurance_name: ''
    });

    const formatInsuranceTypeEvidences = (insuranceTypeEvidences) => {
        const formatted = insuranceTypeEvidences.map((item) => {
            return {
                id: item.id,
                grua_requerido: item.grua_requerido,
                auxilio_vial_requerido: item.auxilio_vial_requerido,
                montacarga_requerido: item.montacarga_requerido,
                corresponsalia_requerido: item.corresponsalia_requerido,
                taxi_requerido: item.taxi_requerido,
                observaciones: item.observaciones,
                order: item.order,
                evidence: {
                    value: item.TypeEvidence.id,
                    label: item.TypeEvidence.evidence,
                },
            };
        });

        return formatted;
    }


    useEffect(() => {
        axiosClient.get(`/insurers/${id}`).then(response => {
            const { insurance_name, InsuranceContacts, InsuranceTypeEvidences } = response.data;

            console.log("response.data", InsuranceTypeEvidences);
            setInitialValues({
                insurance_name,
                insuranceContacts: InsuranceContacts,
                typeEvidences: formatInsuranceTypeEvidences(InsuranceTypeEvidences)
            });
        }).catch(error => {
            console.error('Error fetching insurance data:', error);
        });
    }, [id]);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/insurers">Aseguradoras</Link></li>
                        <li className="breadcrumb-item active">Edición de Aseguradora</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Aseguradora</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Aseguradora</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    insurance_name: yup.string().required("Ingrese el nombre de la Aseguradora"),
                                    insuranceContacts: yup.array().of(
                                        yup.object().shape({
                                            name: yup.string().required('Favor de escribir el nombre'),
                                            position: yup.string().required('Favor de escribir el puesto'),
                                            phone: yup.string().required('Favor de escribir el número telefónico de contacto'),
                                        })
                                    ),
                                    typeEvidences: yup.array().of(
                                        yup.object().shape({
                                            evidence: yup.object().required('Selecciona el Tipo de evidencia'),
                                        })
                                    ),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/insurers/${id}`, values).then(response => {

                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'La aseguradora se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/insurers');
                                    }).catch(err => {
                                        let message = '';
                                        console.log(err.response);
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: err.response.data.message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >


                                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (

                                    <Form noValidate onSubmit={handleSubmit}>

                                        <InsurersFields
                                            handleSubmit={handleSubmit}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            values={values}
                                            touched={touched}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar cambios</Button>{' '}
                                                <Link to={'/catalogs/insurers'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>

                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditInsurance;