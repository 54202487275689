import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../../config/axios.js';
import BankAccountFields from './bankAccountsFields';



function EditBankAccount() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        bank: '',
    });

    useEffect(() => {
        axiosClient.get(`/bankAccounts/${id}`).then(response => {
            const { account_number, Bank } = response.data;
            console.log("response bank", Bank);

            setInitialValues({
                account_number,
                bank_id: Bank ? { value: Bank.id, label: Bank.bank } : ''
            });
        }).catch(error => {
            console.error('Error fetching bank accounts data:', error);
        });
    }, [id]);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Catálogos</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/bankAccounts">Cuentas bancarias</Link></li>
                        <li className="breadcrumb-item active">Edición de Cuenta bancaria</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Cuenta bancaria</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Cuenta bancaria</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    account_number: yup.string().required("Ingrese el número de cuenta"),
                                    bank_id: yup.object().required("Seleccione el banco"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/bankAccounts/${id}`, values).then(response => {
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'La Cuenta bancaria se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/bankAccounts');
                                    }).catch(err => {
                                        const message = err.response.data.message;
                                        console.log(err);

                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <BankAccountFields
                                                values={values}
                                                handleChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar cambios</Button>{' '}
                                                <Link to={'/catalogs/bankAccounts'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditBankAccount;