import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { Panel, PanelBody } from '../../../../components/panel/panel';
import DateTime from 'react-datetime';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import FieldsIncidente from './fieldsIncidente.js';


function OrderStatusCancelar({ formRef, fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB, aseguradora, fechaHoraContacto, fechaHoraReporte }) {
    const { id } = useParams();
    const { Formik } = formik;
    const [tipoCancelaciones, setTipoCancelaciones] = useState([]);
    const [fechaHoraServicioMuertoAutorizado, setServicioMuertoFechaHoraAutorizado] = useState(null);
    const servicioMuertoFechaHoraAutorizado = useRef(null);
    const [textoDiferenciaTiempo, setTextoDiferenciaTiempo] = useState(null);

    const handleRatingClick = (field, value, setFieldValue) => {
        setFieldValue(field, value);
    };

    const diffDates = (init_date, end_date) => {
        let diferenciaTexto = '';
        if (init_date && end_date) {
            const diff = moment(end_date).diff(moment(init_date));
            const duration = moment.duration(diff);

            const dias = Math.floor(duration.asDays());
            const horas = Math.floor(duration.asHours()) % 24;
            const minutos = Math.floor(duration.asMinutes()) % 60;
            const segundos = Math.floor(duration.asSeconds()) % 60;

            if (dias > 0) {
                diferenciaTexto += `${dias} día${dias !== 1 ? 's' : ''}, `;
            }

            if (horas > 0) {
                diferenciaTexto += `${horas} hora${horas !== 1 ? 's' : ''}, `;
            }

            if (minutos > 0) {
                diferenciaTexto += `${minutos} minuto${minutos !== 1 ? 's' : ''}, `;
            }

            if (segundos > 0) {
                diferenciaTexto += `${segundos} segundo${segundos !== 1 ? 's' : ''}`;
            }

            // Eliminar la coma y el espacio final si existen
            if (diferenciaTexto.endsWith(', ')) {
                diferenciaTexto = diferenciaTexto.slice(0, -2);
            }

        }
        setTextoDiferenciaTiempo(diferenciaTexto);

    }

    useEffect(() => {
        diffDates(fechaHoraContacto, fechaHoraServicioMuertoAutorizado);
    }, [fechaHoraServicioMuertoAutorizado]);

    useEffect(() => {
        axiosClient.get('/cancellationTypes/all').then(response => {

            const formattedOptions = response.data.data.map(cancellationType => ({
                value: cancellationType.id,
                label: cancellationType.cancellation_type,
                cancellation_type_code: cancellationType.cancellation_type_code
            }));
            setTipoCancelaciones(formattedOptions);
        }).catch(error => {
            console.error('Error fetching cancellationTypes:', error);
        });


    }, []);

    return (
        <>
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={yup.object().shape({
                    cancellation_type_id: yup.object().required("Selecciona un motivo de cancelación"),
                    servicio_muerto_persona_autoriza: yup.string(),
                    fecha_hora_contacto: yup.date(),
                    servicio_muerto_fecha_hora_autorizado: yup.date().when(['cancellation_type_id'], ([cancellation_type_id], schema) => {
                        return cancellation_type_id !== null && cancellation_type_id !== undefined && cancellation_type_id.cancellation_type_code === 'SERVICIO_MUERTO' ? schema.required("La fecha de autorización es obligatoria") : schema;
                    }),
                    //servicio_muerto_fecha_hora_autorizado: yup.date().min(moment(fechaHoraContacto ? fechaHoraContacto : new Date()).add(1, 'second'), 'La fecha y hora de autorización debe ser posterior a la de contacto'),
                    servicio_muerto_monto_penalizacion: yup.number().required("Ingresa un monto igual o superior a 0."),
                    observations: yup.string().required("Las observaciones son obligatorias.")
                })}
                initialValues={{
                    cancellation_type_id: '',
                    servicio_muerto_persona_autoriza: '',
                    servicio_muerto_fecha_hora_autorizado: '',
                    servicio_muerto_monto_penalizacion: '',
                    observations: '',

                    incident_description: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code: (values.servicio_muerto_monto_penalizacion > 0 || formRef?.values?.totalPendingToPay < 0 || formRef?.values?.totalPendingToPay > 0) ? 'CANCELADO_PAGO_PENDIENTE' : 'CANCELADO',
                        ...values
                    }).then(async response => {
                        Swal.fire({
                            title: 'Orden cancelada',
                            text: 'La orden se ha cancelado.',
                            icon: 'success',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);

                        if (modalInstance) {
                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                        console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    });
                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>

                        <div className="modal-header">
                            <h4 className="modal-title">Cancelación del servicio</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>

                                {fechaHoraReporte && (

                                    <div className={`note mb-2 $ ${formRef?.values?.totalPendingToPay > 0 ? 'alert-danger' : (
                                        formRef?.values?.totalPendingToPay < 0 ? 'alert-warning' : 'alert-primary'
                                    )}`}>
                                        <div className="note-icon">

                                            {formRef?.values?.totalPendingToPay > 0 ? <i className="fa-solid fa-face-frown-open"></i> : (
                                                formRef?.values?.totalPendingToPay < 0 ? <i className="fa-solid fa-sack-dollar"></i> : <i className="fa-solid fa-face-smile-wink"></i>
                                            )}

                                        </div>
                                        <div className="note-content">
                                            <h4><b>
                                                {formRef?.values?.totalPendingToPay > 0 ? 'Orden con adeudo' : (
                                                    formRef?.values?.totalPendingToPay < 0 ? 'Orden con Saldo a Favor' : 'Orden sin adeudos'
                                                )}
                                            </b></h4>

                                            <Row>
                                                <Col sm='12'>

                                                    <Form.Group className="">
                                                        <Form.Label>
                                                            {formRef?.values?.totalPendingToPay > 0 ? 'La Orden presenta un adeudo de: ' : (
                                                                formRef?.values?.totalPendingToPay < 0 ? 'La Orden tiene un saldo a favor de' : 'La orden no tiene adeudos.'
                                                            )}
                                                        </Form.Label>
                                                        {formRef?.values?.totalPendingToPay && formRef?.values?.totalPendingToPay !== 0 && (
                                                            <InputGroup >
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <CurrencyInput
                                                                    name="formRef?.values?.totalPendingToPay"
                                                                    autoComplete='off'
                                                                    className={`form-control`}
                                                                    prefix=""
                                                                    allowNegativeValue={false}
                                                                    decimalsLimit={2}
                                                                    decimalScale={2}
                                                                    decimalSeparator="."
                                                                    groupSeparator=","
                                                                    value={formRef?.values?.totalPendingToPay}
                                                                    disabled={true}
                                                                />
                                                            </InputGroup>
                                                        )}
                                                        {formRef?.values?.totalPendingToPay > 0 ? 'La Orden se cancelará con el estatus Cancelado (pago pendiente) ' : (
                                                            formRef?.values?.totalPendingToPay < 0 ? 'La Orden se cancelará con el estatus Cancelado (pago pendiente)' : ''
                                                        )}


                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>

                                )}

                                <Row className="mb-4">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Motivo de Cancelación</Form.Label>
                                        <Select
                                            name='cancellation_type_id'
                                            options={tipoCancelaciones}
                                            className={errors.cancellation_type_id ? 'is-invalid' : ''}
                                            placeholder="Selecciona el motivo de cancelación"
                                            isClearable={true}
                                            isSearchable={true}
                                            value={values.cancellation_type_id}
                                            onChange={(e) => {
                                                setFieldValue("insurance_id", '');
                                                setFieldValue("cancellation_type_id", e ? e : '');
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cancellation_type_id}
                                        </Form.Control.Feedback>


                                    </Form.Group>
                                </Row>


                                {values.cancellation_type_id && values.cancellation_type_id.cancellation_type_code === 'SERVICIO_MUERTO' && (
                                    <>
                                        {fechaHoraContacto && (
                                            <Row className='mb-2'>
                                                <Form.Group as={Col} md="12" >
                                                    <Form.Label>Fecha y hora de contacto</Form.Label>
                                                    <DateTime
                                                        name='fecha_hora_contacto'
                                                        inputProps={{
                                                            placeholder: 'Fecha y hora de contacto',
                                                            disabled: true
                                                        }}
                                                        closeOnSelect={false}
                                                        locale="es"
                                                        value={fechaHoraContacto}
                                                        dateFormat="dddd DD/MMMM/YYYY"
                                                        timeFormat="hh:mm A"
                                                        initialViewMode="days"
                                                        onChange={(e) => {
                                                            setFieldValue("fecha_hora_contacto", e ? e : '');
                                                        }}
                                                        updateOnView="time"
                                                    />
                                                </Form.Group>
                                            </Row>
                                        )}




                                        <Row className={`mb-${textoDiferenciaTiempo ? '1' : '5'}`}>
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Fecha y hora de autorización de la cancelación del Servicio Muerto {aseguradora ? `por ${aseguradora.label}` : ''}</Form.Label>
                                                <DateTime
                                                    name="servicio_muerto_fecha_hora_autorizado"
                                                    ref={servicioMuertoFechaHoraAutorizado}
                                                    inputProps={{ placeholder: 'Fecha y hora de autorización', readOnly: true }}
                                                    closeOnSelect={false}
                                                    locale="es"
                                                    value={values.servicio_muerto_fecha_hora_autorizado}
                                                    className={errors.servicio_muerto_fecha_hora_autorizado ? 'is-invalid' : ''}
                                                    dateFormat="dddd DD/MMMM/YYYY"
                                                    timeFormat="hh:mm A"
                                                    initialViewMode="days"
                                                    onOpen={(e) => {
                                                        servicioMuertoFechaHoraAutorizado.current.navigate('days');
                                                        setFieldTouched('servicio_muerto_fecha_hora_autorizado', true);
                                                    }}
                                                    onChange={(e) => {
                                                        setFieldValue("servicio_muerto_fecha_hora_autorizado", e ? e : '');
                                                        setServicioMuertoFechaHoraAutorizado(e ? e : null);
                                                    }}
                                                    isInvalid={touched.servicio_muerto_fecha_hora_autorizado && !!errors.servicio_muerto_fecha_hora_autorizado}
                                                    updateOnView="time"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.servicio_muerto_fecha_hora_autorizado}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Row>
                                        {textoDiferenciaTiempo && (
                                            <div className="alert alert-primary fade show mb-5">
                                                Tiempo transcurrido entre la hora de contacto y la autorización de la cancelación: {textoDiferenciaTiempo}
                                            </div>
                                        )}



                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Nombre de la persona que brinda Autorización {aseguradora ? `(aseguradora ${aseguradora.label})` : ''}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="servicio_muerto_persona_autoriza"
                                                    placeholder="Nombre de quien autoriza"
                                                    value={values.servicio_muerto_persona_autoriza}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.servicio_muerto_persona_autoriza}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.servicio_muerto_persona_autoriza}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>



                                    </>
                                )}

                                {values.cancellation_type_id && values.cancellation_type_id.cancellation_type_code === 'INCIDENTE' && (
                                    <FieldsIncidente
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                    />
                                )}

                                <Row className='mb-3'>
                                    <Form.Group as={Col} md='12' >
                                        <Form.Label>Monto de penalización a cobrar (cargo adicional a lo ya adeudado)</Form.Label>
                                        <InputGroup
                                            className={`mb-3 ${errors.servicio_muerto_monto_penalizacion ? 'is-invalid' : ''}`}
                                        >
                                            <InputGroup.Text >$</InputGroup.Text>
                                            <CurrencyInput
                                                className={`form-control ${errors.servicio_muerto_monto_penalizacion ? 'is-invalid' : ''}`}
                                                prefix=""
                                                allowNegativeValue={false}
                                                decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                                decimalScale={2}
                                                decimalSeparator="."
                                                groupSeparator=","
                                                name="servicio_muerto_monto_penalizacion"
                                                value={values.servicio_muerto_monto_penalizacion}
                                                onValueChange={(value) => setFieldValue('servicio_muerto_monto_penalizacion', value)}
                                                autoComplete='off'
                                            />
                                        </InputGroup>
                                        {errors.servicio_muerto_monto_penalizacion ? (
                                            <div className="invalid-feedback">{errors.servicio_muerto_monto_penalizacion}</div>
                                        ) : null}

                                    </Form.Group>
                                </Row>

                                {values.cancellation_type_id && (
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Observaciones de la Cancelación</Form.Label>
                                            <textarea
                                                name="observations"
                                                className={`form-control ${errors.observations ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    setFieldValue('observations', e.target.value);
                                                }}
                                                value={values.observations}
                                            ></textarea>

                                            <Form.Control.Feedback type="invalid">
                                                {errors.observations}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Row>
                                )}



                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" className='btn-danger' onClick={handleSubmit} disabled={Object.keys(errors).length > 0}>Cancelar orden</Button>
                        </div>

                    </>
                )
                }
            </Formik >


        </>
    );
}

export default OrderStatusCancelar;