import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

function BankFields({ values, handleChange, touched, errors }) {
    return (
        <>
            <Form.Group as={Col} md="12">
                <Form.Label>Nombre del Banco</Form.Label>
                <Form.Control
                    type="text"
                    name="bank"
                    placeholder="Nombre del Banco"
                    value={values.bank}
                    onChange={handleChange}
                    isInvalid={touched.bank && !!errors.bank}
                    autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                    {errors.bank}
                </Form.Control.Feedback>
            </Form.Group>

        </>
    );
}

export default BankFields;