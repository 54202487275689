const Menu = [
  {
    path: '/orders', icon: 'fa-solid fa-envelope-open-text', title: 'Cartas de servicio'
    //, label: '8'
  },
  {
    path: '/reports', icon: 'fa-solid fa-clipboard-check', title: 'Reportes',
    children: [
      { path: '/reports/income', title: 'Ingresos' },
      { path: '/reports/fuel', title: 'Consumo de combustible' },
      { path: '/reports/salaries', title: 'Auxiliar de nómina' },
      { path: '/reports/prices', title: 'Lista de precios' },
      {
        path: '/reports/crane', title: 'Grúas',
        children: [
          { path: '/reports/crane/maintenance', title: 'Servicios de mantenimiento' },
          { path: '/reports/crane/insurance', title: 'Seguro de grúa' },
          { path: '/reports/crane/permission', title: 'Tenencia y permisos' }
        ]
      },
      {
        path: '/reports/operators', title: 'Operadores',
        children: [
          { path: '/reports/operators/licenses', title: 'Licencias de manejo' },
          { path: '/reports/operators/holidays', title: 'Vacaciones' }
        ]
      }
    ]
  },

  {
    path: '/catalogs', icon: 'fa-solid fa-book', title: 'Catálogos',
    children: [
      {
        path: '', title: 'Tarifas',
        children: [
          { path: '/catalogs/localRates', title: 'Locales' },
          { path: '/catalogs/foreignRates', title: 'Foráneas' },
        ]
      },
      //{ path: '/catalogs/agreements', title: 'Convenios' },
      {
        path: '', title: 'Aseguradoras',
        children: [
          { path: '/catalogs/insurers', title: 'Listado de aseguradoras' },
          { path: '/catalogs/typeEvidences', title: 'Tipos de Evidencias' },
        ]
      },
      {
        path: '', title: 'Bancos y Cuentas bancarias',
        children: [
          { path: '/catalogs/banks', title: 'Bancos' },
          { path: '/catalogs/bankAccounts', title: 'Cuentas bancarias' },
        ]
      },
      { path: '/catalogs/locations', title: 'Ubicaciones' },
      { path: '/catalogs/brandsModels', title: 'Marcas y Modelos de Vehículos' },
      { path: '/catalogs/shieldingTypes', title: 'Categorías de Blindaje' },
      { path: '/catalogs/cranes', title: 'Grúas' },
      { path: '/catalogs/assistanceVehicles', title: 'Vehículos de asistencia' },
      { path: '/catalogs/liftTrucks', title: 'Montacargas' },
      //{ path: '/catalogs/headquarters', title: 'Bases' },
      //{ path: '/catalogs/customers', title: 'Clientes' },
      //{ path: '/catalogs/drivers', title: 'Operadores' },
      //{ path: '/catalogs/vehycle-types', title: 'Tipos de vehículos' },
      { path: '/catalogs/crane-types', title: 'Categorías de grúas' },
      { path: '/catalogs/colors', title: 'Colores' },
    ]
  },
  {
    path: '/settings', icon: 'fa-solid fa-screwdriver-wrench', title: 'Configuración',
    children: [
      { path: '/settings/users', title: 'Usuarios' },
      { path: '/settings/roles', title: 'Roles y permisos' }
    ]
  },
]

export default Menu;