import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { Panel, PanelBody } from '../../../../components/panel/panel.jsx';
import DateTime from 'react-datetime';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import FieldsIncidente from './fieldsIncidente.js';


function OrderStatusFinalizarCancelacion({ formRef, fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB, aseguradora, fechaHoraContacto, fechaHoraReporte }) {
    const { id } = useParams();
    const { Formik } = formik;
    const [tipoCancelaciones, setTipoCancelaciones] = useState([]);
    const [fechaHoraServicioMuertoAutorizado, setServicioMuertoFechaHoraAutorizado] = useState(null);
    const servicioMuertoFechaHoraAutorizado = useRef(null);
    const [textoDiferenciaTiempo, setTextoDiferenciaTiempo] = useState(null);

    const handleChangeStatus = (values) => {
        axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
            order_status_code: 'FINALIZAR_CANCELADO',
            ...values
        }).then(async response => {
            Swal.fire({
                title: 'Orden cancelada',
                text: 'La orden se ha cancelado.',
                icon: 'success',
                confirmButtonText: 'Cerrar',
                cancelButtonText: 'Cancelar',
                showCancelButton: false,
            });

            const result = await fetchCurrentOrderStatusByOrder();
            setCurrentOrderStatus(result);
            console.table("setCurrentOrderStatus", result);

            if (modalInstance) {
                modalInstance.hide();
            }
            reloadOrderDataFromDB();

        }).catch(err => {
            console.log(err.response);
            Swal.fire({
                title: 'Ups!',
                text: 'Ha ocurrido un error',
                icon: 'error',
                confirmButtonText: 'Cerrar',
                cancelButtonText: 'Cancelar',
                showCancelButton: false,
            });

        });
    }
    const diffDates = (init_date, end_date) => {
        let diferenciaTexto = '';
        if (init_date && end_date) {
            const diff = moment(end_date).diff(moment(init_date));
            const duration = moment.duration(diff);

            const dias = Math.floor(duration.asDays());
            const horas = Math.floor(duration.asHours()) % 24;
            const minutos = Math.floor(duration.asMinutes()) % 60;
            const segundos = Math.floor(duration.asSeconds()) % 60;

            if (dias > 0) {
                diferenciaTexto += `${dias} día${dias !== 1 ? 's' : ''}, `;
            }

            if (horas > 0) {
                diferenciaTexto += `${horas} hora${horas !== 1 ? 's' : ''}, `;
            }

            if (minutos > 0) {
                diferenciaTexto += `${minutos} minuto${minutos !== 1 ? 's' : ''}, `;
            }

            if (segundos > 0) {
                diferenciaTexto += `${segundos} segundo${segundos !== 1 ? 's' : ''}`;
            }

            // Eliminar la coma y el espacio final si existen
            if (diferenciaTexto.endsWith(', ')) {
                diferenciaTexto = diferenciaTexto.slice(0, -2);
            }

        }
        setTextoDiferenciaTiempo(diferenciaTexto);

    }

    useEffect(() => {
        diffDates(fechaHoraContacto, fechaHoraServicioMuertoAutorizado);
    }, [fechaHoraServicioMuertoAutorizado]);

    useEffect(() => {
        axiosClient.get('/cancellationTypes/all').then(response => {

            const formattedOptions = response.data.data.map(cancellationType => ({
                value: cancellationType.id,
                label: cancellationType.cancellation_type,
                cancellation_type_code: cancellationType.cancellation_type_code
            }));
            setTipoCancelaciones(formattedOptions);
        }).catch(error => {
            console.error('Error fetching cancellationTypes:', error);
        });


    }, []);

    return (
        <>
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={yup.object().shape({
                    observations: yup.string().required("Las observaciones son obligatorias."),
                })}
                initialValues={{
                    observations: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    let text = null;
                    if (formRef?.values?.totalPendingToPay > 0) {
                        text = 'La Orden presenta un adeudo y al cancelar ya no se podrán realizar modificaciones. ¿Estás seguro que deseas continuar con la cancelación de la orden?';
                    } else if (formRef?.values?.totalPendingToPay < 0) {
                        text = 'La Orden tiene Saldo a Favor del Cliente y al cancelar ya no se podrán realizar modificaciones. ¿Estás seguro que deseas continuar con la cancelación de la orden?.';
                    }

                    if (text) {
                        Swal.fire({
                            title: 'Confirmar cancelación de Orden',
                            text,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Sí, cancelar la Orden.',
                            cancelButtonText: 'No, realizaremos modificaciones posteriores.',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                handleChangeStatus(values);
                            }
                        })
                    } else {
                        handleChangeStatus(values);
                    }




                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>

                        <div className="modal-header">
                            <h4 className="modal-title">Finalizar Cancelación del servicio</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>

                                {fechaHoraReporte && (

                                    <div className={`note mb-2 $ ${formRef?.values?.totalPendingToPay > 0 ? 'alert-danger' : (
                                        formRef?.values?.totalPendingToPay < 0 ? 'alert-warning' : 'alert-primary'
                                    )}`}>
                                        <div className="note-icon">

                                            {formRef?.values?.totalPendingToPay > 0 ? <i className="fa-solid fa-face-frown-open"></i> : (
                                                formRef?.values?.totalPendingToPay < 0 ? <i className="fa-solid fa-sack-dollar"></i> : <i className="fa-solid fa-face-smile-wink"></i>
                                            )}

                                        </div>
                                        <div className="note-content">
                                            <h4><b>
                                                {formRef?.values?.totalPendingToPay > 0 ? 'Orden con adeudo' : (
                                                    formRef?.values?.totalPendingToPay < 0 ? 'Orden con Saldo a Favor' : 'Orden sin adeudos'
                                                )}
                                            </b></h4>

                                            <Row>
                                                <Col sm='12'>

                                                    <Form.Group className="">
                                                        <Form.Label>
                                                            {formRef?.values?.totalPendingToPay > 0 ? 'La Orden presenta un adeudo de: ' : (
                                                                formRef?.values?.totalPendingToPay < 0 ? 'La Orden tiene un saldo a favor de' : 'La orden no tiene adeudos.'
                                                            )}
                                                        </Form.Label>
                                                        {formRef?.values?.totalPendingToPay && formRef?.values?.totalPendingToPay !== 0 && (
                                                            <InputGroup >
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <CurrencyInput
                                                                    name="formRef?.values?.totalPendingToPay"
                                                                    autoComplete='off'
                                                                    className={`form-control`}
                                                                    prefix=""
                                                                    allowNegativeValue={false}
                                                                    decimalsLimit={2}
                                                                    decimalScale={2}
                                                                    decimalSeparator="."
                                                                    groupSeparator=","
                                                                    value={formRef?.values?.totalPendingToPay}
                                                                    disabled={true}
                                                                />
                                                            </InputGroup>
                                                        )}
                                                        {formRef?.values?.totalPendingToPay > 0 ? 'Debe escribirse el motivo por el que se cancela la orden aún teniendo adeudos' : (
                                                            formRef?.values?.totalPendingToPay < 0 ? 'Debe escribirse el motivo por el que se cancela la orden con Saldo a favor del Cliente' : ''
                                                        )}

                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>

                                )}

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>
                                            {formRef?.values?.totalPendingToPay > 0 ? 'Motivo por el que se termina de cancelar la orden aún teniendo adeudos:' : (
                                                formRef?.values?.totalPendingToPay < 0 ? 'Motivo por el que se termina de cancelar la orden con Saldo a favor del Cliente' : 'Observaciones:'
                                            )}
                                        </Form.Label>
                                        <textarea
                                            name="observations"
                                            className={`form-control ${errors.observations ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setFieldValue('observations', e.target.value);
                                            }}
                                            value={values.observations}
                                        ></textarea>

                                        <Form.Control.Feedback type="invalid">
                                            {errors.observations}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Row>



                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" className='btn-danger' onClick={handleSubmit} disabled={Object.keys(errors).length > 0}>Cancelar orden</Button>
                        </div>

                    </>
                )
                }
            </Formik >


        </>
    );
}

export default OrderStatusFinalizarCancelacion;