import React from 'react';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CurrencyInput from 'react-currency-input-field';
import InputGroup from 'react-bootstrap/InputGroup';
import { ErrorMessage, FieldArray } from 'formik';
import OrderStatusAsignadaSinEnviarSeleccOperador from '../orderStatus/orderStatusAsignadaSinEnviarSeleccOperador';

function OrderAmountsVariables({ gruas, operadores, montacargas, vehiculosAsistencia, values, handleChange, setFieldTouched, setFieldValue, errors }) {



    return (
        <>
            {values.service_location_type_id.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false && (
                <Row className="mb-3">

                    <Form.Group as={Col} md="12" >
                        <Form.Label>Distancia ajustada para tarifa (km.)</Form.Label>
                        <InputGroup >

                            <CurrencyInput
                                autoComplete='off'
                                allowDecimals={false}
                                className={`form-control ${errors.km ? 'is-invalid' : ''}`}
                                prefix=""
                                allowNegativeValue={false}
                                decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                decimalScale={2}
                                decimalSeparator="."
                                groupSeparator=","
                                name="km"
                                value={values.km}
                                onValueChange={(value) => setFieldValue('km', value)}
                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                            />
                            <InputGroup.Text >km.</InputGroup.Text>
                        </InputGroup>
                        <span className='invalid-feedback' style={{ display: 'block' }}>
                            <ErrorMessage name="km" />
                        </span>

                    </Form.Group>


                </Row>
            )}




            {(values.someWithShielding || values.someWithCarga || values.someWithVolumen) && (
                <Row className='mb-1'>
                    <div className="col-md-6 pb-2">
                        <div className="alert alert-warning alert-dismissible fade show mb-0">
                            Se encuentra registrado al menos un vehículo con blindaje, carga y/o volumen
                            <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                        </div>
                    </div>

                </Row>
            )}


            <Row className="mb-3" >

                {(values.grua || values.montacarga || values.corresponsalia) && (
                    <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>{values.montacarga ? 'Grúa que trasladará el montacargas' : 'Grúa No.'}</Form.Label>
                        <Select
                            name="crane_id"
                            options={gruas}
                            className={errors.crane_id ? 'is-invalid' : ''}
                            placeholder="Selecciona una grúa"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.crane_id}
                            onChange={(e) => {
                                setFieldTouched("crane_id", true);
                                setFieldValue("crane_id", e);
                            }}
                            isDisabled={values.orderStatuses?.CIERRE?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.crane_id}
                        </Form.Control.Feedback>


                    </Form.Group>
                )}


                {(values.auxilio_vial || values.taxi) && (
                    <Form.Group as={Col} md="6" name="orderAmountsVariables_vehiculoAsistenciaVial">
                        <Form.Label>Vehículo{values.vehiculos_asistencia && values.vehiculos_asistencia.length > 1 ? 's' : ''} de asistencia Vial</Form.Label>
                        <Select
                            name="vehiculos_asistencia"
                            options={vehiculosAsistencia}
                            className={errors.vehiculos_asistencia ? 'is-invalid' : ''}
                            placeholder="Selecciona uno o más vehículos de asistencia"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.vehiculos_asistencia}
                            isMulti
                            onChange={(e) => {
                                setFieldValue("vehiculos_asistencia", e ? e : '');
                            }}
                            isDisabled={values.orderStatuses?.CIERRE?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.vehiculos_asistencia}
                        </Form.Control.Feedback>

                    </Form.Group>
                )}

                {values.montacarga ?
                    <Form.Group as={Col} md="6" name="orderAmountsVariables_sinAsignarMontacargas">
                        <Form.Label>Montacargas</Form.Label>
                        <Select
                            name="montacargas"
                            options={montacargas}
                            className={errors.montacargas ? 'is-invalid' : ''}
                            placeholder="Selecciona uno o más montacargas"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.montacargas}
                            isMulti
                            onChange={(e) => {
                                setFieldValue("montacargas", e ? e : '');
                            }}
                            isDisabled={values.orderStatuses?.ENVIADA?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.montacargas}
                        </Form.Control.Feedback>

                    </Form.Group>
                    : ''
                }


                <Form.Group as={Col} md="6" name="orderAmountsVariables_sinAsignarOperador">

                    <OrderStatusAsignadaSinEnviarSeleccOperador
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        operadores={operadores}
                    />


                </Form.Group>

            </Row>



        </>
    );
}

export default OrderAmountsVariables;