import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import OrderFieldsRegistrarUbicacionOrigenDestino from './orderFieldsRegistrarUbicacionOrigenDestino';
import MapMultipleDestination from '../orderLocations/mapMultipleDestination';


const libraries = ['places'];

function OrderFieldsUbicacionAtencion({ values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors, tiposLocalizacionServicio, modalidadesTarifa }) {

    return (
        <>

            <Row className="mb-3">
                <Form.Group as={Col} md="12" >

                    <MapMultipleDestination
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched}
                        errors={errors}
                        tiposLocalizacionServicio={tiposLocalizacionServicio}
                        modalidadesTarifa={modalidadesTarifa}
                    />

                </Form.Group>
            </Row>

            <Row className="mb-3">
                <OrderFieldsRegistrarUbicacionOrigenDestino
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errors={errors}
                />
            </Row>
        </>
    );
}

export default OrderFieldsUbicacionAtencion