import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './ComponentToPrint.css';
import OrderFieldsResultAmount from '../orderSections/orderFieldsResultAmount';
import OrderAmountsFieldArrayGenericoPrint from './orderAmountsFieldArrayGenericoPrint';
import OrderAmountsFieldArrayAdicionalPrint from './orderAmountsFieldArrayAdicionalPrint';

function OrderMontosPrint({ orderData }) {

    return (
        <>
            <form className="row row-cols-sm-auto align-items-center mb-0 mt-0" action="/" method="POST">

                {orderData?.auxilio_vial && (
                    <Col sm='3'>
                        <div className="col-sm-12 simple-text">
                            <label className="form-label col-form-label">VEHÍCULO DE ASISTENCIA VIAL: </label>
                            <span className=''> {orderData?.vehiculos_asistencia && orderData?.vehiculos_asistencia.length > 0
                                ? orderData?.vehiculos_asistencia.map((assisVehi, index) => (
                                    assisVehi.label
                                )).join(', ')
                                : 'SIN VEHÍCULO DE ASISTENCIA ASIGNADO'
                            }</span>
                        </div>
                    </Col>
                )}

                {orderData?.crane_id && (
                    <Col sm='3'>
                        <div className="form-floating">
                            <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.crane_id?.label} />
                            <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">{orderData?.montacarga ? 'GRÚA QUE TRASLADA MONTACARGAS' : 'GRÚA'}</label>
                        </div>
                    </Col>
                )}

                {orderData?.montacarga && (
                    <Col sm='3'>
                        <div className="form-floating">
                            <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.montacargas?.label} />
                            <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">MONTACARGAS</label>
                        </div>
                    </Col>
                )}

                {orderData.service_location_type_id.service_location_code === 'FORANEO' && orderData.manejar_tarifas_establecidas === false && (
                    <Col sm='2'>
                        <div className="form-floating">
                            <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.km + ' km.'} />
                            <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">DISTANCIA TARIFARIA</label>
                        </div>
                    </Col>
                )}

                <Col sm='4'>
                    <div className="col-sm-12 simple-text">
                        <label className="form-label col-form-label">OPERADOR{orderData.operadores && orderData.operadores.length > 1 ? 'ES: ' : ': '}</label>
                        <span className=''>
                            {orderData?.operadores && orderData?.operadores.length > 0
                                ? orderData?.operadores.map((operador, index) => (
                                    <React.Fragment key={index}>
                                        {operador?.encargado ?
                                            <span className='fw-bold'>
                                                {`${operador?.label} (ENCARGADO)`}
                                            </span>
                                            :
                                            `${operador?.label}`
                                        }

                                    </React.Fragment>
                                )).reduce((prev, curr) => [prev, ', ', curr])
                                : 'SIN OPERADOR ASIGNADO'
                            }
                        </span>
                    </div>
                </Col>

                {orderData.montacarga === true && (
                    <>
                        <div className="col-sm-3">
                            <label className="form-label col-form-label float-end fs-9px">MODALIDAD DE TARIFA</label>
                        </div>
                        <div className="col-sm-3">
                            <input type="text" className="form-control  my-small-input bg-white " disabled value={orderData?.manejar_tarifas_establecidas === true ? 'SÍ' : 'NO'} />
                        </div>
                    </>
                )}

                <Col sm='2'>
                    <div className="form-floating">
                        <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.manejar_precios_netos === true ? 'SÍ' : 'NO'} />
                        <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">PRECIOS NETOS</label>
                    </div>
                </Col>



            </form>

            <Row className='mb-0 pb-0'>
                <div className='col-md-12'>
                    <div className="table-responsive">
                        <table className="table table-amount form-print mb-0">
                            <thead>
                                <tr>
                                    <th width="10%"></th>
                                    <th width="30%"></th>
                                    <th width="30%"></th>
                                    <th width="30%" className='fs-9px'></th>
                                </tr>
                            </thead>



                            {orderData.grua && (
                                <>
                                    {orderData.service_location_type_id.service_location_code === 'FORANEO' && orderData.manejar_tarifas_establecidas === false ? (
                                        <tbody key="montos_grua_foraneo">
                                            <OrderAmountsFieldArrayGenericoPrint
                                                print={true}
                                                values={orderData}
                                                setFieldValue={() => { }}
                                                setFieldTouched={() => { }}
                                                handleChange={() => { }}
                                                errors={[]}
                                                tipoServicio='GRÚA'
                                                primeraVariableLabel='TARIFA POR KILÓMETRO'
                                                segundaVariableLabel='KILOMETRAJE'
                                                segundaVariableSufix='km.'
                                                terceraVariableLabel='BANDERAZO'
                                                cuartaVariableLabel='CASETAS'
                                                primeraVariableKey='grua_tarifa_kilometro'
                                                segundaVariableKey='km'
                                                terceraVariableKey='grua_banderazo'
                                                cuartaVariableKey='grua_casetas'
                                                subTotalKeyName='grua_subtotal'
                                                subTotalLabel='SUBTOTAL'
                                                tarifasCalculadas={[]}
                                                tarifasCasetas={[]}
                                                disabled={true}
                                            />
                                        </tbody>
                                    ) : (
                                        <tbody key="montos_grua_local">
                                            <OrderAmountsFieldArrayGenericoPrint
                                                print={true}
                                                values={orderData}
                                                setFieldValue={() => { }}
                                                setFieldTouched={() => { }}
                                                handleChange={() => { }}
                                                errors={[]}
                                                tipoServicio='GRÚA'
                                                terceraVariableLabel={orderData.service_location_type_id.service_location_code === 'LOCAL' ? 'TARIFA LOCAL' : 'TARIFA FORÁNEA ESTABLECIDA'}
                                                terceraVariableKey='grua_subtotal'
                                                disabled={true}
                                            />
                                        </tbody>
                                    )}
                                </>



                            )}


                            {orderData.auxilio_vial && (
                                <>
                                    {orderData.service_location_type_id.service_location_code === 'FORANEO' && orderData.manejar_tarifas_establecidas === false ? (

                                        <tbody key="montos_aux_vial">
                                            <OrderAmountsFieldArrayGenericoPrint
                                                print={true}
                                                values={orderData}
                                                setFieldValue={() => { }}
                                                setFieldTouched={() => { }}
                                                handleChange={() => { }}
                                                errors={[]}
                                                tipoServicio='AUXILIO VIAL'
                                                primeraVariableLabel='TARIFA POR KILÓMETRO'
                                                segundaVariableLabel='KILOMETRAJE'
                                                segundaVariableSufix='km.'
                                                terceraVariableLabel='BANDERAZO'
                                                cuartaVariableLabel='CASETAS'
                                                primeraVariableKey='aux_vial_tarifa_kilometro'
                                                segundaVariableKey='km'
                                                terceraVariableKey='aux_vial_banderazo'
                                                cuartaVariableKey='aux_vial_casetas'
                                                subTotalKeyName='aux_vial_subtotal'
                                                tarifasCalculadas={[]}
                                                tarifasCasetas={[]}
                                                disabled={true}
                                            />

                                        </tbody>

                                    ) : (
                                        <tbody key="montos_grua_local">
                                            <OrderAmountsFieldArrayGenericoPrint
                                                print={true}
                                                values={orderData}
                                                setFieldValue={() => { }}
                                                setFieldTouched={() => { }}
                                                handleChange={() => { }}
                                                errors={[]}
                                                tipoServicio='AUXILIO VIAL'
                                                terceraVariableLabel={orderData.service_location_type_id.service_location_code === 'LOCAL' ? 'TARIFA LOCAL' : 'TARIFA FORÁNEA ESTABLECIDA'}
                                                terceraVariableKey='aux_vial_subtotal'
                                                disabled={true}
                                            />
                                        </tbody>
                                    )}
                                </>
                            )}


                            {orderData.montacarga === true && (
                                <>
                                    {orderData.manejar_tarifas_establecidas === false ? (

                                        <tbody key="montos_montacarga_calculo">
                                            <OrderAmountsFieldArrayGenericoPrint
                                                print={true}
                                                values={orderData}
                                                setFieldValue={() => { }}
                                                setFieldTouched={() => { }}
                                                handleChange={() => { }}
                                                errors={[]}
                                                tipoServicio='MONTACARGA'
                                                primeraVariableLabel='TARIFA POR HORA O FRACCIÓN'
                                                segundaVariableLabel='HORAS'
                                                segundaVariableSufix='horas'
                                                terceraVariableLabel='TRASLADO'
                                                primeraVariableKey='montacargas_costo_hora'
                                                segundaVariableKey='montacargas_horas'
                                                terceraVariableKey='montacargas_traslado'
                                                subTotalKeyName='montacargas_subtotal'
                                                casetasKey={null}
                                                tarifasCalculadas={[]}
                                                tarifasCasetas={[]}
                                                disabled={true}
                                            />
                                        </tbody>
                                    ) : (
                                        <tbody key="montos_montacarga_tarifa_fija">
                                            <OrderAmountsFieldArrayGenericoPrint
                                                print={true}
                                                values={orderData}
                                                setFieldValue={() => { }}
                                                setFieldTouched={() => { }}
                                                handleChange={() => { }}
                                                errors={[]}
                                                tipoServicio='MONTACARGA'
                                                terceraVariableLabel={'TARIFA ESTABLECIDA'}
                                                terceraVariableKey='montacargas_subtotal'
                                                disabled={true}
                                            />
                                        </tbody>
                                    )
                                    }
                                </>
                            )}


                            {orderData.corresponsalia && (
                                <tbody key="montos_corresponsalia">
                                    <OrderAmountsFieldArrayGenericoPrint
                                        print={true}
                                        values={orderData}
                                        setFieldValue={() => { }}
                                        setFieldTouched={() => { }}
                                        handleChange={() => { }}
                                        errors={[]}
                                        tipoServicio='CORRESPONSALÍA'
                                        terceraVariableLabel='GASTOS CORRESPONSALÍA'
                                        terceraVariableKey='corresponsalia_subtotal'
                                        disabled={true}
                                    />
                                </tbody>

                            )}

                            {orderData.taxi && (
                                <tbody key="montos_taxi">
                                    <OrderAmountsFieldArrayGenericoPrint
                                        print={true}
                                        values={orderData}
                                        setFieldValue={() => { }}
                                        setFieldTouched={() => { }}
                                        handleChange={() => { }}
                                        errors={[]}
                                        tipoServicio='TAXI'
                                        terceraVariableLabel='GASTOS TAXI'
                                        terceraVariableKey='taxi_subtotal'
                                        disabled={true}
                                    />
                                </tbody>
                            )}


                            {orderData.someWithShielding && (
                                <tbody key="montos_shielding">
                                    <OrderAmountsFieldArrayGenericoPrint
                                        print={true}
                                        values={orderData}
                                        setFieldValue={() => { }}
                                        setFieldTouched={() => { }}
                                        handleChange={() => { }}
                                        errors={[]}
                                        tipoServicio='BLINDAJE'
                                        terceraVariableLabel='BLINDAJE'
                                        terceraVariableKey='monto_blindaje'
                                        disabled={true}
                                    />
                                </tbody>
                            )}

                            {(orderData.someWithCarga || orderData.someWithVolumen) && (
                                <tbody key="montos_carga_volumen">
                                    <OrderAmountsFieldArrayGenericoPrint
                                        print={true}
                                        values={orderData}
                                        setFieldValue={() => { }}
                                        setFieldTouched={() => { }}
                                        handleChange={() => { }}
                                        errors={[]}
                                        tipoServicio={`${orderData.someWithCarga && orderData.someWithVolumen ? 'CARGA Y VOLUMEN' : orderData.someWithCarga ? 'CARGA' : 'VOLUMEN'}`}
                                        terceraVariableLabel={`${orderData.someWithCarga && orderData.someWithVolumen ? 'CARGA Y VOLUMEN' : orderData.someWithCarga ? 'CARGA' : 'VOLUMEN'}`}
                                        terceraVariableKey='monto_carga_volumen'
                                        disabled={true}
                                    />
                                </tbody>
                            )}
                            {(orderData?.servicio_muerto_monto_penalizacion) && (
                                <tbody key="montos_servicio_muerto_monto_penalizacion">
                                    <OrderAmountsFieldArrayGenericoPrint
                                        print={true}
                                        values={orderData}
                                        setFieldValue={() => { }}
                                        setFieldTouched={() => { }}
                                        handleChange={() => { }}
                                        errors={[]}
                                        tipoServicio={`PENALIZACIÓN POR CANCELACIÓN`}
                                        terceraVariableLabel={`PENALIZACIÓN POR CANCELACIÓN`}
                                        terceraVariableKey='servicio_muerto_monto_penalizacion'
                                        disabled={true}
                                    />
                                </tbody>
                            )}




                            {orderData?.montosExtras && orderData?.montosExtras.length > 0
                                ? orderData?.montosExtras.map((montoExtra, index) => (

                                    <OrderAmountsFieldArrayAdicionalPrint
                                        print={true}
                                        key={`monto_extra_${index}`}
                                        index={index}
                                        values={orderData}
                                        errors={[]}
                                        arrayHelpers={[]}
                                        montosExtras={orderData?.montosExtras}
                                        setFieldValue={() => { }}
                                        setFieldTouched={() => { }}
                                        disabled={true}
                                    />

                                ))
                                : null
                            }


                        </table>
                    </div>
                </div>
            </Row>

            <OrderFieldsResultAmount
                values={orderData}
                errors={[]}
                print={true}
                setFieldTouched={() => { }}
                setFieldValue={() => { }}
            />
        </>
    );
}

export default OrderMontosPrint;