import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import CurrencyInput from 'react-currency-input-field';
import OrderAmountsShowSuggestedRates from './orderAmountsShowSuggestedRates';


function OrderAmountsFieldArrayAdicional({ print, disabled, index, values, errors, arrayHelpers, montosExtras, setFieldValue, setFieldTouched }) {

    return (
        <tbody>
            <tr>
                <td className='align-middle text-center'>

                    <div className="d-flex align-items-center ">
                        <div >
                            {(!print && !disabled) && (
                                <button
                                    type="button"
                                    className="btn btn-danger btn-icon btn-circle btn-sm"
                                    onClick={() => {
                                        arrayHelpers.remove(index); // Elimina la fila del FieldArray
                                    }}
                                >
                                    <i className="fa-solid fa-minus"></i>
                                </button>
                            )}
                        </div>
                        <div className={`ms-1 ${print ? 'fs-9px' : ''}`}>
                            ADICIONAL
                        </div>
                    </div>


                </td>
                <td>
                </td>
                <td>

                    <span className="text-dark">
                        {print ?
                            <>
                                <span className={`float-end ${print ? 'fs-9px' : 'fs-11px'}`}>{montosExtras[index].monto_extra_descripcion}</span>
                            </>
                            :
                            <Field
                                placeholder="Descripción del monto"
                                className={`form-control ${print ? 'my-small-input' : ''}`}
                                name={`montosExtras.${index}.monto_extra_descripcion`}
                                autoComplete="off"
                                disabled={disabled}
                            />
                        }


                    </span>
                    <span className='invalid-feedback' style={{ display: 'block' }}>
                        {!print && (<ErrorMessage name={`montosExtras.${index}.monto_extra_descripcion`} />)}

                    </span>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <Form.Group as={Col} md='12' className="d-flex align-items-center">

                            <InputGroup className={`${errors.monto_extra_monto ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}>
                                <InputGroup.Text className={`${print ? 'smaller' : ''}`}>$</InputGroup.Text>
                                <CurrencyInput
                                    autoComplete='off'
                                    className={`form-control ${errors.monto_extra_monto ? 'is-invalid' : ''}`}
                                    prefix=""
                                    allowNegativeValue={false}
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    decimalSeparator="."
                                    groupSeparator=","
                                    name={`montosExtras.${index}.monto_extra_monto`}
                                    value={values.montosExtras[index].monto_extra_monto}
                                    onValueChange={(value) => {
                                        setFieldValue(`montosExtras.${index}.monto_extra_monto`, value);
                                        setFieldTouched(`montosExtras.${index}.monto_extra_monto`, true);
                                    }}
                                    disabled={print || disabled ? true : false}
                                />
                            </InputGroup>

                        </Form.Group>


                    </div>
                    <span className='invalid-feedback' style={{ display: 'block' }}>
                        {!print && (<ErrorMessage name={`montosExtras.${index}.monto_extra_monto`} />)}

                    </span>

                </td>


            </tr>
        </tbody>
    );
}

export default OrderAmountsFieldArrayAdicional;