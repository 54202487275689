import React from 'react';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Field, ErrorMessage } from 'formik';
import Col from 'react-bootstrap/Col';
import CurrencyInput from 'react-currency-input-field';
import OrderAmountsShowSuggestedRates from './orderAmountsShowSuggestedRates';

function OrderAmountsFieldArrayGenerico({ print, values, setFieldValue, setFieldTouched, handleChange, errors, tipoServicio, subTotalKeyName, banderazoKey, casetasKey, tarifasCalculadas, tarifasCasetas, primeraVariableLabel, segundaVariableLabel, segundaVariableSufix, terceraVariableLabel, cuartaVariableLabel, primeraVariableKey, segundaVariableKey, terceraVariableKey, cuartaVariableKey, disabled }) {

    return (
        <>

            <tr className={`${print ? 'fs-9px ' : ''}`} >
                <td rowSpan={cuartaVariableLabel ? 3 : 2} className='align-middle text-center'><Form.Label>{tipoServicio}</Form.Label></td>
                {primeraVariableLabel && primeraVariableKey && (
                    <td>


                        <Row className=''>
                            <span className={`text-end ${print ? 'fs-9px' : ''} `}>
                                {primeraVariableLabel}
                            </span>
                        </Row>
                        <div className="d-flex align-items-center">
                            <Form.Group as={Col} md='12' className="d-flex align-items-center">
                                <div className="me-1">
                                    <OrderAmountsShowSuggestedRates
                                        tarifasCalculadas={tarifasCalculadas}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        keyValue={primeraVariableKey}
                                        variableName='rate_kilometer'
                                    />
                                </div>
                                <InputGroup
                                    className={`${primeraVariableKey ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                >
                                    <InputGroup.Text className={`${print ? 'smaller' : ''}`} >$</InputGroup.Text>
                                    <CurrencyInput
                                        autoComplete='off'
                                        className={`form-control ${errors[primeraVariableKey] ? 'is-invalid' : ''} ${print ? 'smaller my-small-floating-input form-print' : ''}`}
                                        prefix=""
                                        allowNegativeValue={false}
                                        decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                        decimalScale={2}
                                        decimalSeparator="."
                                        groupSeparator=","
                                        name={`${primeraVariableKey}`}
                                        value={values[primeraVariableKey]}
                                        onValueChange={(value) => {
                                            setFieldTouched(primeraVariableKey, true);
                                            setFieldValue(primeraVariableKey, value);
                                        }}
                                        disabled={disabled}

                                    />

                                </InputGroup>


                            </Form.Group>

                        </div>
                        <span className='invalid-feedback' style={{ display: 'block' }}>

                            {!print && (<ErrorMessage name={`${primeraVariableKey}`} />)}
                        </span>

                    </td>

                )}

                {segundaVariableLabel && segundaVariableKey && (
                    <td>

                        <Row>
                            <span className={`text-end ${print ? 'fs-9px' : ''}`}>
                                {segundaVariableLabel}
                            </span>
                        </Row>
                        <Form.Group as={Col} md='12' >
                            <InputGroup
                                className={`${segundaVariableKey ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                            >

                                <CurrencyInput
                                    autoComplete='off'
                                    className={`form-control ${errors[segundaVariableKey] ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                    prefix=""
                                    allowNegativeValue={false}
                                    decimalsLimit={0}  // Limita la cantidad de decimales a 2
                                    decimalScale={0}
                                    allowDecimals={false}
                                    decimalSeparator="."
                                    groupSeparator=","
                                    name={`${segundaVariableKey}`}
                                    value={values[segundaVariableKey]}
                                    onValueChange={(value) => {
                                        setFieldTouched(segundaVariableKey, true);
                                        setFieldValue(segundaVariableKey, value);

                                    }}
                                    disabled={disabled}

                                />
                                <InputGroup.Text className={`${print ? 'smaller' : ''}`}>{segundaVariableSufix}</InputGroup.Text>

                            </InputGroup>
                            <span className='invalid-feedback' style={{ display: 'block' }}>
                                {!print && (<ErrorMessage name={`${segundaVariableKey}`} />)}

                            </span>

                        </Form.Group>

                    </td>
                )}

                {subTotalKeyName && (
                    <td>

                        <Form.Group as={Col} md='12' >
                            <Form.Label></Form.Label>
                            <InputGroup
                                className={`${subTotalKeyName ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                            >
                                <InputGroup.Text className={`${print ? 'smaller' : ''}`} >$</InputGroup.Text>
                                <CurrencyInput
                                    autoComplete='off'
                                    disabled
                                    className={`form-control ${errors[subTotalKeyName] ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                    prefix=""
                                    allowNegativeValue={false}
                                    decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                    decimalScale={2}
                                    decimalSeparator="."
                                    groupSeparator=","
                                    name={`${subTotalKeyName}`}
                                    value={values[subTotalKeyName]}
                                    onValueChange={(value) => {
                                        setFieldTouched(subTotalKeyName, true);
                                        setFieldValue(subTotalKeyName, value);

                                    }}


                                />

                            </InputGroup>
                            <span className='invalid-feedback' style={{ display: 'block' }}>
                                {!print && (<ErrorMessage name={`${subTotalKeyName}`} />)}
                            </span>

                        </Form.Group>
                    </td>
                )}


            </tr>
            {terceraVariableKey && (

                <tr className={`${print ? 'fs-11px' : ''}`} >
                    <td>

                    </td>
                    <td >
                        <div className="d-flex justify-content-end">
                            <span className={`${print ? 'fs-9px' : ''}`}>{terceraVariableLabel}</span>
                            <div className="ms-1">
                                <OrderAmountsShowSuggestedRates
                                    tarifasCalculadas={tarifasCalculadas}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    keyValue={terceraVariableKey}
                                    variableName='fixed_rate'
                                />
                            </div>
                        </div>

                    </td>
                    <td>

                        <Row>
                            <div className="d-flex align-items-center">
                                <Form.Group as={Col} md='12' className="d-flex align-items-center">

                                    <InputGroup className={`${errors[terceraVariableKey] ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}>
                                        <InputGroup.Text className={`${print ? 'smaller' : ''}`}>$</InputGroup.Text>
                                        <CurrencyInput
                                            autoComplete='off'
                                            className={`form-control ${errors[terceraVariableKey] ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                            prefix=""
                                            allowNegativeValue={false}
                                            decimalsLimit={2}
                                            decimalScale={2}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            name={`${terceraVariableKey}`}
                                            value={values[terceraVariableKey]}
                                            onValueChange={(value) => {
                                                setFieldTouched(terceraVariableKey, true);
                                                setFieldValue(terceraVariableKey, value);

                                            }}
                                            disabled={disabled}
                                        />
                                    </InputGroup>

                                </Form.Group>
                            </div>
                        </Row>
                        <Row className='mt-2'>

                            {terceraVariableKey === 'corresponsalia_subtotal' && (
                                <Form.Group as={Col} md='12' className="d-flex align-items-center">

                                    <textarea
                                        name="corresponsalia_observaciones"
                                        placeholder="Observaciones corresponsalía"
                                        className={`form-control ${errors.corresponsalia_observaciones ? 'is-invalid' : ''} ${print ? 'my-small-input' : ''}`}
                                        onChange={(e) => {
                                            setFieldTouched('corresponsalia_observaciones', true);
                                            setFieldValue('corresponsalia_observaciones', e.target.value);
                                        }}
                                        value={values.corresponsalia_observaciones}
                                        autoComplete="off"
                                        disabled={disabled}
                                        maxLength={255}
                                    ></textarea>
                                </Form.Group>
                            )}
                        </Row>


                        <span className='invalid-feedback' style={{ display: 'block' }}>
                            {!print && (<ErrorMessage name={`${terceraVariableKey}`} />)}
                        </span>

                    </td>

                </tr>
            )}

            {cuartaVariableLabel && cuartaVariableKey && (
                <tr className={`${print ? 'fs-11px' : ''}`} >
                    <td>

                    </td>
                    <td>
                        <div className="d-flex justify-content-end">
                            <span className={`${print ? 'fs-9px' : ''}`}>{cuartaVariableLabel}</span>
                            <div className="ms-1">
                                <OrderAmountsShowSuggestedRates
                                    tarifasCalculadas={tarifasCasetas}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    keyValue={cuartaVariableKey}
                                    variableName='fixed_rate'
                                />
                            </div>
                        </div>
                    </td>
                    <td>

                        <div className="d-flex align-items-center">
                            <Form.Group as={Col} md='12' className="d-flex align-items-center">


                                <InputGroup
                                    className={`${errors[cuartaVariableKey] ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                >
                                    <InputGroup.Text className={`${print ? 'smaller' : ''}`}>$</InputGroup.Text>
                                    <CurrencyInput
                                        autoComplete='off'
                                        className={`form-control ${errors[cuartaVariableKey] ? 'is-invalid' : ''} ${print ? 'smaller' : ''}`}
                                        prefix=""
                                        allowNegativeValue={false}
                                        decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                        decimalScale={2}
                                        decimalSeparator="."
                                        groupSeparator=","
                                        name={`${cuartaVariableKey}`}
                                        value={values[cuartaVariableKey]}
                                        onValueChange={(value) => {
                                            setFieldTouched(cuartaVariableKey, true);
                                            setFieldValue(cuartaVariableKey, value);

                                        }}
                                        disabled={disabled}
                                    />

                                </InputGroup>

                            </Form.Group>


                        </div>
                        <span className='invalid-feedback' style={{ display: 'block' }}>
                            {!print && (<ErrorMessage name={`${cuartaVariableKey}`} />)}
                        </span>

                    </td>
                </tr>

            )}

        </>
    );
}

export default OrderAmountsFieldArrayGenerico;