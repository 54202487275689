import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';

import moment from 'moment';
import 'moment/locale/es';
import TablePayments from '../orderPayments/tablePayments.js';
import ResumeStatusPayment from '../orderPayments/resumeStatusPayment.js';
import OrderStatusPagadoTable from './orderStatusPagadoTable.js';


function OrderStatusPagado({ paymentTypeCode, fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB, order_status_code, orderValues }) {
    const { id } = useParams();
    const { Formik } = formik;
    const formikRef = useRef(null);
    const tablePaymentsRef = useRef(null);
    const [paymentTypes, setPaymentTypes] = useState([]);



    const handleChangeStatus = (values) => {
        axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
            order_status_code,
            payments: values.payments,
            payment_type_code: paymentTypeCode,
            observations: values.observaciones
        }).then(async response => {
            const result = await fetchCurrentOrderStatusByOrder();
            setCurrentOrderStatus(result);
            console.table("setCurrentOrderStatus", result);

            if (modalInstance) {

                modalInstance.hide();
            }
            reloadOrderDataFromDB();

        }).catch(err => {
            console.log(err.response);
            Swal.fire({
                title: 'Ups!',
                text: 'Ha ocurrido un error',
                icon: 'error',
                confirmButtonText: 'Cerrar',
                cancelButtonText: 'Cancelar',
                showCancelButton: false,
            });

        });
    }

    useEffect(() => {
        axiosClient.get('/paymentTypes/all').then(response => {

            const formattedOptions = response.data.data.map(paymentType => ({
                value: paymentType.id,
                label: paymentType.payment_type_name,
                code: paymentType.payment_type_code,
                id: paymentType.id
            }));
            setPaymentTypes(formattedOptions);
        }).catch(error => {
            console.error('Error fetching paymentTypes:', error);
        });
    }, [id]);


    return (
        <>

            <Formik
                innerRef={formikRef}
                validationSchema={yup.object().shape({
                    payments: yup.array().of(
                        yup.object().shape({
                            payment_date: yup.date().required("Seleccione la fecha de pago"),
                            payment_method_code: yup.string().required("Seleccione el método de pago"),
                            payment_amount: yup.number().required("Escriba el monto").positive('El monto debe ser positivo'),
                            bank_account_id: yup.object().when(['payment_method_code'], ([payment_method_code], schema) => {
                                return (payment_method_code === 'TRANSFER' ? schema.required('La cuenta bancaria es obligatoria al seleccionar la transferencia') : schema.nullable());
                            }),
                        }),
                    ),
                    observaciones: yup.string().when(['order_status_code', 'totalPendingExcedente', 'totalPendingToPay', 'tipo_pago_id', 'clickedButton'], ([order_status_code, totalPendingExcedente, totalPendingToPay, tipo_pago_id, clickedButton], schema) => {

                        return clickedButton === 'updateStatus'
                            ? ((order_status_code === 'PAGADO_CONTADO' && totalPendingExcedente > 0)
                                ? schema.required('Ingresa el motivo por el que no se cubre el monto Excedente Asegurado')
                                : (order_status_code === 'PAGADO_CREDITO' && totalPendingToPay > 0)
                                    ? schema.required('Ingresa el motivo por el que no se cubre el monto Total de la Orden')
                                    : schema.nullable())
                            : schema.nullable();

                    }),
                })}
                initialValues={{
                    payments: [],
                    observaciones: '',
                    order_status_code
                }}
                onSubmit={(values, { setSubmitting, setFieldValue, setFieldTouched }) => {
                    setSubmitting(false);

                    //console.log("order_status_code", order_status_code);
                    //console.log("order_status_code", values.payments);
                    values.payments.forEach(payment => {
                        payment.payment_date = moment(payment.payment_date).format();
                        //payment.payment_type_code = paymentTypeCode;
                    });
                    //console.log("clickedButton", values.clickedButton);

                    if (values.clickedButton === 'savePayments') {
                        //console.log("datos a enviar", values.payments);
                        axiosClient.put(`/payments/savePayments/${id}`, {
                            payments: values.payments,
                            payment_type_code: paymentTypeCode
                        }).then(async response => {
                            const result = await fetchCurrentOrderStatusByOrder();
                            setCurrentOrderStatus(result);

                            if (modalInstance) {

                                modalInstance.hide();
                            }
                            reloadOrderDataFromDB();

                            /*  setFieldValue('payments', []);
                              setFieldTouched('payments', false);
*/


                            if (tablePaymentsRef.current) {
                                console.log("tablePaymentsRef.current true");
                                tablePaymentsRef.current.fetchData();
                            } else {
                                console.log("tablePaymentsRef.current false");
                            }

                        }).catch(err => {
                            console.log(err.response);
                            Swal.fire({
                                title: 'Ups!',
                                text: 'Ha ocurrido un error',
                                icon: 'error',
                                confirmButtonText: 'Cerrar',
                                cancelButtonText: 'Cancelar',
                                showCancelButton: false,
                            });

                        });
                    } else if (values.clickedButton === 'updateStatus') {
                        console.log("updateStatus clickedButton");
                        console.log("order payments", values);


                        let text = ''
                        switch (orderValues?.tipo_pago_id?.code) {
                            case 'CONTADO':
                                if (values?.totalPendingToPay > 0) {
                                    text = `Existe un monto de $${values?.totalPendingToPay} pendiente por pagar. ¿Estás seguro que deseas continuar con el siguiente estatus?. El adeudo se mantendrá registrado en la orden.`;
                                }
                                break;
                            case 'CREDITO':
                                if (values?.totalPendingToPay > 0) {
                                    text = `Existe un monto de $${values?.totalPendingToPay} pendiente por pagar. ¿Estás seguro que deseas continuar con el siguiente estatus?. El adeudo se mantendrá registrado en la orden.`;
                                }
                                break;
                            case 'CONTADO/CREDITO':
                                if (order_status_code === 'PAGADO_CONTADO' && values?.totalPendingExcedente > 0) {
                                    text = `Existe un monto Excedente Asegurado de $${values?.totalPendingExcedente} por pagar. ¿Estás seguro que deseas continuar con el siguiente estatus?. El adeudo se mantendrá registrado en la orden.`;
                                } else if (order_status_code === 'PAGADO_CREDITO' && values?.totalPendingToPay > 0) {
                                    text = `Existe un monto de $${values?.totalPendingToPay} pendiente por pagar. ¿Estás seguro que deseas continuar con el siguiente estatus?. El adeudo se mantendrá registrado en la orden.`;
                                }
                                break;
                            default:
                                break;
                        }
                        console.log("text", text);
                        console.log("text totalPendingExcedente", orderValues?.totalPendingExcedente);
                        console.log("text totalPendingToPay", orderValues?.totalPendingToPay);
                        console.log("text values?.tipo_pago_id?.code", orderValues?.tipo_pago_id?.code);
                        console.log("text order_status_code", order_status_code);
                        if (text) {
                            Swal.fire({
                                title: 'Existe monto por pagar',
                                text,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Sí, cambiar estatus y mantener adeudo.',
                                cancelButtonText: 'Cancelar',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    handleChangeStatus(values);
                                }
                            })
                        } else {
                            handleChangeStatus(values);
                        }
                    }

                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched, submitForm }) => (

                    <OrderStatusPagadoTable
                        paymentTypeCode={paymentTypeCode}
                        order_status_code={order_status_code}
                        orderValues={orderValues}
                        handleSubmit={handleSubmit}
                        tablePaymentsRef={tablePaymentsRef}
                    />

                )}
            </Formik >

        </>
    );
}

export default OrderStatusPagado;